import * as yup from 'yup';
import { CustomerFieldsEnum } from '../../../types';

export const customerInformationFormSchema = () =>
  yup.object().shape({
    [CustomerFieldsEnum.City]: yup
      .string()
      .max(100, 'City cannot be longer than 100 characters')
      .required('City field is required'),
    [CustomerFieldsEnum.Name]: yup
      .string()
      .max(100, 'Name cannot be longer than 100 characters')
      .required('Name field is required'),
    [CustomerFieldsEnum.CompanyName]: yup
      .string()
      .max(100, 'Company name cannot be longer than 100 characters')
      .required('Company name field is required'),
    [CustomerFieldsEnum.Country]: yup
      .string()
      .max(100, 'Country cannot be longer than 100 characters')
      .required('Country field is required'),
    [CustomerFieldsEnum.TaxId]: yup
      .string()
      .max(100, 'Tax ID cannot be longer than 100 characters')
      .required('Tax ID field is required'),
    [CustomerFieldsEnum.PhoneNumber]: yup
      .string()
      .matches(
        /^\+\d{1,3}[-.\s]?\(?\d{1,4}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,4}$/,
        'Invalid phone number format',
      )
      .max(100, 'Phone number cannot be longer than 100 characters')
      .required('Phone number field is required'),
    [CustomerFieldsEnum.PostalCode]: yup
      .string()
      .matches(/^[0-9-]+$/, 'Postal code can only contain numbers and hyphens')
      .max(100, 'Postal code cannot be longer than 100 characters')
      .required('Postal code field is required'),
    [CustomerFieldsEnum.Email]: yup
      .string()
      .email('Invalid email format')
      .max(100, 'Email cannot be longer than 100 characters')
      .required('Email field is required'),
    [CustomerFieldsEnum.Address]: yup
      .string()
      .max(100, 'Address cannot be longer than 100 characters')
      .required('Address field is required'),
  });

import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { MouseEvent } from 'react';
import { Order, ReferralCodeUsageType } from '../../interfaces';
import ReferralDetailsTableHead from './ReferralDetailsTableHead';

interface ReferralDetailsTableProps {
  handleRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof Partial<ReferralCodeUsageType>,
  ) => void;
  order: Order;
  orderBy: keyof Partial<ReferralCodeUsageType>;
  visibleRows: Partial<ReferralCodeUsageType>[];
  emptyRows: number;
}

const ReferralDetailsTable = ({
  handleRequestSort,
  order,
  orderBy,
  visibleRows,
  emptyRows,
}: ReferralDetailsTableProps) => {
  return (
    <TableContainer sx={{ borderRadius: '8px 8px 0px 0px' }}>
      <Table aria-labelledby="tableTitle" sx={{ minWidth: 750 }}>
        <ReferralDetailsTableHead
          onRequestSort={handleRequestSort}
          order={order}
          orderBy={orderBy}
        />
        <TableBody>
          {visibleRows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                key={row.id}
                hover
                role="checkbox"
                sx={{ cursor: 'pointer', background: '#FFF' }}
                tabIndex={-1}
              >
                <TableCell component="th" id={labelId} scope="row" sx={{ fontSize: '16px' }}>
                  {row.name}
                </TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{row.email}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{row.role}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>
                  {new Date(row.createdAt ?? '').toLocaleString()}
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 53 * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ReferralDetailsTable;

import { Box, Button, Dialog, DialogTitle, FormHelperText } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { EditReferralCodeNoteEnum } from '../../types';
import { editReferralCodeNoteSchema } from './editReferralCodeNoteSchema';
import { ReferralCodesDocument } from '../../hooks/api/referrals/referrals.generated';
import { useEditReferralCodeNoteMutation } from '../../hooks/api/editReferralCodeNote/editReferralCodeNote.generated';
import { TextInput } from '../Inputs';

export interface EditDialogProps {
  open: boolean;
  onClose: () => void;
  id?: string;
  note?: string;
}

interface EditReferralCodeNoteValues {
  [EditReferralCodeNoteEnum.Note]: string;
}

export const EditDialog = (props: EditDialogProps) => {
  const { onClose, open, id, note } = props;
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditReferralCodeNoteValues>({
    resolver: yupResolver(editReferralCodeNoteSchema()),
  });

  const [err, setErr] = useState();

  const [EditReferralCodeNote] = useEditReferralCodeNoteMutation({
    refetchQueries: [{ query: ReferralCodesDocument }],
  });

  const onSubmit = async (values: EditReferralCodeNoteValues) => {
    if (!id) {
      return;
    }
    try {
      await EditReferralCodeNote({
        variables: {
          id,
          note: values[EditReferralCodeNoteEnum.Note],
        },
      });
      onClose();
    } catch (error: any) {
      console.error('Error creating referral code:', error.message || error);
      setErr(error.message);
    }
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (!note) {
      return;
    }
    setValue(EditReferralCodeNoteEnum.Note, note);
  }, [note]);

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Edit note</DialogTitle>
      <Box padding="20px">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box display="flex" flexDirection="column" gap={4}>
            <TextInput {...register('note')} errors={errors} label="Note" />
            {err && <FormHelperText error>{err}</FormHelperText>}
          </Box>

          <Box display="flex" flexDirection="row" justifyContent="end" pt={6} width="100%">
            <Button
              color="secondary"
              style={{ textTransform: 'none', fontWeight: '700' }}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </Dialog>
  );
};

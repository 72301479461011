import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type CreateMovieMutationVariables = Types.Exact<{
  input: Types.CreateMovieInputType;
}>;

export type CreateMovieMutation = {
  __typename?: 'Mutation';
  createMovie: {
    __typename?: 'MovieType';
    customerId: string;
    name: string;
    validFrom: string;
    validUntil: string;
    actorName: string;
    agencyName: string;
    isActive: boolean;
    isEmbed: boolean;
    url: string;
    previewMovie: { __typename?: 'UploadedMovieFileResultType'; fileId: string; name: string };
    fullMovie: { __typename?: 'UploadedMovieFileResultType'; fileId: string; name: string };
    actionConfig: {
      __typename?: 'MovieActionConfigType';
      redirect?: {
        __typename?: 'RedirectActionConfigType';
        buttonLabel: string;
        targetUrl: string;
        openInNewTab?: boolean | null;
      } | null;
      emailInput?: {
        __typename?: 'EmailInputActionConfigType';
        buttonLabelBeforeClick: string;
        buttonLabelAfterClick: string;
        targetUrl: string;
        disclaimer?: string | null;
      } | null;
      copyToClipboard?: {
        __typename?: 'CopyToClipboardActionConfigType';
        label: string;
        labelAfterClick: string;
        value: string;
        iconOn: boolean;
      } | null;
    };
  };
};

export const CreateMovieDocument = gql`
  mutation createMovie($input: CreateMovieInputType!) {
    createMovie(input: $input) {
      customerId
      name
      validFrom
      validUntil
      actorName
      agencyName
      isActive
      isEmbed
      url
      previewMovie {
        fileId
        name
      }
      fullMovie {
        fileId
        name
      }
      actionConfig {
        redirect {
          buttonLabel
          targetUrl
        }
        emailInput {
          buttonLabelBeforeClick
          buttonLabelAfterClick
          targetUrl
          disclaimer
        }
        copyToClipboard {
          label
          labelAfterClick
          value
          iconOn
        }
      }
    }
  }
`;
export type CreateMovieMutationFn = Apollo.MutationFunction<
  CreateMovieMutation,
  CreateMovieMutationVariables
>;

/**
 * __useCreateMovieMutation__
 *
 * To run a mutation, you first call `useCreateMovieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMovieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMovieMutation, { data, loading, error }] = useCreateMovieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMovieMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMovieMutation, CreateMovieMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMovieMutation, CreateMovieMutationVariables>(
    CreateMovieDocument,
    options,
  );
}
export type CreateMovieMutationHookResult = ReturnType<typeof useCreateMovieMutation>;
export type CreateMovieMutationResult = Apollo.MutationResult<CreateMovieMutation>;
export type CreateMovieMutationOptions = Apollo.BaseMutationOptions<
  CreateMovieMutation,
  CreateMovieMutationVariables
>;

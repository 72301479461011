import {
  AppBar,
  Badge,
  Box,
  CssBaseline,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useState } from 'react';
import { theme } from '../styles/theme';
import { Closer2Icon, CustomerIcon, LogoutIcon } from '../assets/icons';
import PanelSidebar from './PanelSidebar';

const drawerWidth = 240;
const PanelLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { logout, user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        elevation={0}
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: {
            xs: theme.palette.primary.main,
            sm: theme.palette.background.default,
          },
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: { xs: 'space-between', sm: 'flex-end' },
          }}
        >
          {isMobile && (
            <Box sx={{ height: '17px', width: '96px' }}>
              <Closer2Icon />
            </Box>
          )}
          <IconButton onClick={handleMenuClick}>
            <Badge>
              <CustomerIcon fill={!isMobile ? '#222222' : '#FFFFFF'} />
              <Typography color="#000" pl={2}>
                {user?.email}
              </Typography>
            </Badge>
          </IconButton>
          <Menu anchorEl={anchorEl} onClose={handleMenuClose} open={Boolean(anchorEl)}>
            <MenuItem onClick={handleLogoutClick}>
              {' '}
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              Log Out
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box
        aria-label="mailbox folders"
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
          backgroundColor: theme.palette.primary.main,
        }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <PanelSidebar drawerWidth={drawerWidth} />
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)`, marginTop: 42 },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default PanelLayout;

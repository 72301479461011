import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type CreateReferralCodeMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  code: Types.Scalars['String']['input'];
  note?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type CreateReferralCodeMutation = {
  __typename?: 'Mutation';
  createReferralCode: {
    __typename?: 'ReferralCodeType';
    id: string;
    name: string;
    code: string;
    note: string;
    createdAt: string;
    isActive: boolean;
  };
};

export const CreateReferralCodeDocument = gql`
  mutation createReferralCode($name: String!, $code: String!, $note: String) {
    createReferralCode(name: $name, code: $code, note: $note) {
      id
      name
      code
      note
      createdAt
      isActive
    }
  }
`;
export type CreateReferralCodeMutationFn = Apollo.MutationFunction<
  CreateReferralCodeMutation,
  CreateReferralCodeMutationVariables
>;

/**
 * __useCreateReferralCodeMutation__
 *
 * To run a mutation, you first call `useCreateReferralCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferralCodeMutation, { data, loading, error }] = useCreateReferralCodeMutation({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useCreateReferralCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReferralCodeMutation,
    CreateReferralCodeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateReferralCodeMutation, CreateReferralCodeMutationVariables>(
    CreateReferralCodeDocument,
    options,
  );
}
export type CreateReferralCodeMutationHookResult = ReturnType<typeof useCreateReferralCodeMutation>;
export type CreateReferralCodeMutationResult = Apollo.MutationResult<CreateReferralCodeMutation>;
export type CreateReferralCodeMutationOptions = Apollo.BaseMutationOptions<
  CreateReferralCodeMutation,
  CreateReferralCodeMutationVariables
>;

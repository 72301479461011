import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { EditDialog } from '../EditDialog/EditDialog';

interface HomeTopPanelProps {
  referralName?: string;
  id?: string;
  note?: string;
}

const ReferralDetailsTopPanel: FC<HomeTopPanelProps> = ({
  referralName = 'No referral name',
  id,
  note,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const [isCodeCopied, setIsCodeCopied] = useState(false);

  const flexDirectionValue = isLargeScreen ? 'row' : 'column';

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGenerateLink = () => {
    const tempInput = document.createElement('input');

    tempInput.value = `https://closer2.io?ref=${referralName}`;

    document.body.appendChild(tempInput);

    tempInput.select();

    document.execCommand('copy');

    document.body.removeChild(tempInput);

    setIsCodeCopied(true);
  };

  return (
    <Box
      display="flex"
      flexDirection={flexDirectionValue}
      justifyContent="space-between"
      mb={6}
      mt="59px"
      width="100%"
    >
      <Typography fontSize={36} fontWeight={700}>
        {`Name: ${referralName}`}
      </Typography>
      <Button
        color="secondary"
        onClick={handleClickOpen}
        style={{
          padding: isLargeScreen ? '0 90px' : '10px 20px',
          textTransform: 'none',
          maxWidth: isLargeScreen ? '100%' : '30%',
          maxHeight: '48px',
          marginRight: '10px',
        }}
        variant="outlined"
      >
        Edit note
      </Button>
      <Button
        color="secondary"
        onClick={handleGenerateLink}
        style={{
          padding: isLargeScreen ? '0 90px' : '10px 20px',
          textTransform: 'none',
          maxWidth: isLargeScreen ? '100%' : '30%',
          maxHeight: '48px',
          marginRight: '10px',
        }}
        variant="outlined"
      >
        {isCodeCopied ? 'Copied to clipboard!' : 'Generate link'}
      </Button>
      <EditDialog id={id} note={note} onClose={handleClose} open={open} />
    </Box>
  );
};

export default ReferralDetailsTopPanel;

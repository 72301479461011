import React, { FC, useRef, useState, MouseEvent } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Button, CircularProgress, FormHelperText, Typography } from '@mui/material';
import { useMovieUpload } from '../../hooks';

interface DragAndDropInputProps {
  onFileUploadComplete: (data: { name: string; fileId: string }) => void;
  initialFile?: { name: string; fileId: string };
  label?: string;
  errors?: any;
  name?: string;
  disabled?: boolean;
}
const DragAndDropInput: FC<DragAndDropInputProps> = ({
  onFileUploadComplete,
  disabled,
  label,
  errors,
  name,
  initialFile,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const fieldError = errors && (errors[name ?? '']?.fileId || errors[name ?? '']?.name);
  const { uploadMovie, uploading } = useMovieUpload();
  const isInitialFileValid = initialFile && (initialFile.name !== '' || initialFile.fileId !== '');
  const isFileLoaded = file || isInitialFileValid;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (acceptedFiles) => {
      if (!disabled || !uploading) {
        setFile(acceptedFiles[0]);
        await uploadMovie(acceptedFiles[0], (fileId: string, uploadedFileName: string) => {
          onFileUploadComplete({ fileId, name: uploadedFileName });
        });
      }
    },
    accept: {
      'video/*': ['.mp4', '.mov', '.wmv', '.avi'],
    },
    multiple: false,
  });

  const handleDelete = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setFile(null);
    onFileUploadComplete({ name: '', fileId: '' });
  };

  const handleUploadClick = () => {
    if (!disabled || !uploading) {
      inputRef.current?.click();
    }
  };

  return (
    <Box>
      <Typography pb={1}>{label}</Typography>
      <Box
        {...getRootProps()}
        style={{
          pointerEvents: disabled || uploading ? 'none' : 'auto',
          border: fieldError ? '1px dashed #d32f2f' : '1px dashed #DBDBDB',
          padding: '20px',
          textAlign: 'center',
          maxWidth: '320px',
        }}
      >
        <input ref={inputRef} {...getInputProps()} disabled={uploading || disabled} />
        {uploading && (
          <CircularProgress
            color="secondary"
            size={24}
            style={{
              position: 'relative',
              margin: 'auto',
            }}
          />
        )}
        {isFileLoaded ? (
          <>
            <Typography>{file?.name ? file?.name : initialFile?.name}</Typography>
            <Button
              disabled={uploading || disabled}
              onClick={(e) => handleDelete(e)}
              style={{ fontWeight: 700, color: '#D60000' }}
            >
              DELETE
            </Button>
          </>
        ) : (
          <>
            <Typography>Drag and Drop</Typography>
            <Button
              color="secondary"
              disabled={uploading || disabled}
              onClick={handleUploadClick}
              style={{ fontWeight: 700 }}
              type="button"
            >
              UPLOAD
            </Button>
          </>
        )}
      </Box>
      {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
    </Box>
  );
};

export default DragAndDropInput;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type EditReferralCodeNoteMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  note: Types.Scalars['String']['input'];
}>;

export type EditReferralCodeNoteMutation = {
  __typename?: 'Mutation';
  editReferralCodeNote: { __typename?: 'ReferralCodeType'; id: string; note: string };
};

export const EditReferralCodeNoteDocument = gql`
  mutation EditReferralCodeNote($id: String!, $note: String!) {
    editReferralCodeNote(id: $id, note: $note) {
      id
      note
    }
  }
`;
export type EditReferralCodeNoteMutationFn = Apollo.MutationFunction<
  EditReferralCodeNoteMutation,
  EditReferralCodeNoteMutationVariables
>;

/**
 * __useEditReferralCodeNoteMutation__
 *
 * To run a mutation, you first call `useEditReferralCodeNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReferralCodeNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReferralCodeNoteMutation, { data, loading, error }] = useEditReferralCodeNoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useEditReferralCodeNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EditReferralCodeNoteMutation,
    EditReferralCodeNoteMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditReferralCodeNoteMutation, EditReferralCodeNoteMutationVariables>(
    EditReferralCodeNoteDocument,
    options,
  );
}
export type EditReferralCodeNoteMutationHookResult = ReturnType<
  typeof useEditReferralCodeNoteMutation
>;
export type EditReferralCodeNoteMutationResult =
  Apollo.MutationResult<EditReferralCodeNoteMutation>;
export type EditReferralCodeNoteMutationOptions = Apollo.BaseMutationOptions<
  EditReferralCodeNoteMutation,
  EditReferralCodeNoteMutationVariables
>;

import React, { useState } from 'react';
import { Box, IconButton, Snackbar, styled, TextField, Typography } from '@mui/material';
import { CopyToClipboardIcon } from '../../assets/icons';

interface CopyToClipboardButtonProps {
  textToCopy: string;
}

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
  },
});

const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({ textToCopy }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setOpen(true);
      })
      .catch((err) => {
        console.error('Failed to copy text to clipboard', err);
      });
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <CopyToClipboardIcon />
      </IconButton>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={1000}
        message="Copied to clipboard"
        onClose={() => setOpen(false)}
        open={open}
        sx={{ top: { sm: 90, xs: 70 }, zIndex: 20 }}
      />
    </>
  );
};

const CopyToClipboardInput: React.FC<{ initialValue: string }> = ({ initialValue }) => {
  const [inputValue, setInputValue] = useState(initialValue);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  return (
    <Box>
      <Typography pb={1}>Copy the url and send it to the client</Typography>
      <Box alignItems="center" display="flex" flexDirection="row" maxWidth={452}>
        <Box width="100%">
          <StyledTextField disabled fullWidth onChange={handleInputChange} value={inputValue} />
        </Box>
        <Box bgcolor="white" marginBottom={0} marginLeft={-6} zIndex={10}>
          <CopyToClipboardButton textToCopy={inputValue} />
        </Box>
      </Box>
    </Box>
  );
};

export default CopyToClipboardInput;

import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { MovieType, Order } from '../../interfaces';
import VideosTableHead from './VideosTableHead';

interface VideosTableProps {
  handleRequestSort: (event: MouseEvent<unknown>, property: keyof Partial<MovieType>) => void;
  order: Order;
  orderBy: keyof Partial<MovieType>;
  visibleRows: Partial<MovieType>[];
  emptyRows: number;
}

const VideosTable = ({
  handleRequestSort,
  order,
  orderBy,
  visibleRows,
  emptyRows,
}: VideosTableProps) => {
  const navigate = useNavigate();
  return (
    <TableContainer sx={{ borderRadius: '8px 8px 0px 0px' }}>
      <Table aria-labelledby="tableTitle" sx={{ minWidth: 750 }}>
        <VideosTableHead onRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
        <TableBody>
          {visibleRows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                key={row.id}
                hover
                onClick={() => navigate(`${row.id}`)}
                role="checkbox"
                sx={{ cursor: 'pointer', background: '#FFF' }}
                tabIndex={-1}
              >
                <TableCell component="th" id={labelId} scope="row" sx={{ fontSize: '16px' }}>
                  {row.name}
                </TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{row.url}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{row.numberOfViews}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{row.numberOfClicks}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{row.viewsInFull}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{row.ctaClicks}</TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 53 * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default VideosTable;

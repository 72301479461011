import { useMemo } from 'react';
import { Box, TablePagination } from '@mui/material';
import { MovieType } from '../../interfaces';
import { usePagination } from '../../hooks';
import { createMovieData, getComparator, stableSort } from '../utils';
import VideosToolbar from './VideosToolbar';
import { useMoviesQuery } from '../../hooks/api/movies/movies.generated';
import VideosTable from './VideosTable';

const VideosView = () => {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleRequestSort,
  } = usePagination();

  const { data: moviesData } = useMoviesQuery();
  const rows =
    moviesData?.movies.movies.map((movie) =>
      createMovieData(
        movie.id ?? '',
        movie.companyName ?? '',
        movie.url ?? '',
        movie.numberOfViews ?? 0,
        movie.numberOfClicks ?? 0,
        movie.viewsInFull ?? 0,
        movie.ctaClicks ?? 0,
        movie.isActive ?? false,
      ),
    ) || [];

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows as any[], getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ) as Partial<MovieType>,
    [order, orderBy, page, rowsPerPage, rows],
  );

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <VideosToolbar />
      <VideosTable
        emptyRows={emptyRows}
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy as keyof Partial<MovieType>}
        visibleRows={visibleRows as Partial<MovieType>[]}
      />
      <TablePagination
        component="div"
        count={rows.length}
        labelRowsPerPage=""
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Box>
  );
};
export default VideosView;

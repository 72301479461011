import { useMemo } from 'react';
import { Box, Button, TablePagination, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomersToolbar from './CustomersToolbar';
import { CustomerType } from '../../interfaces';
import MobileCustomersAccordion from './CustomersMobileAccordion';
import CustomersTable from './CustomersTable';
import { usePagination } from '../../hooks';
import { useCustomersQuery } from '../../hooks/api/customers/customers.generated';
import { createData, getComparator, stableSort } from '../utils';

const CustomersView = () => {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleRequestSort,
  } = usePagination();

  const navigate = useNavigate();
  const { data: customersData } = useCustomersQuery();
  const rows =
    customersData?.customers.customers.map((customer) =>
      createData(
        customer.id,
        customer.email,
        customer.moviesCount,
        customer.createdAt,
        customer.companyName,
      ),
    ) || [];

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const flexDirectionValue = isLargeScreen ? 'row' : 'column';
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const visibleRows = useMemo(
    () =>
      stableSort(rows as any[], getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Box
        display="flex"
        flexDirection={flexDirectionValue}
        justifyContent="space-between"
        pt="59px"
        width="100%"
      >
        <CustomersToolbar />
        <Button
          color="secondary"
          onClick={() => navigate('/customers/add-new-customer')}
          style={{
            padding: isLargeScreen ? '0 90px' : '10px 20px',
            textTransform: 'none',
            maxWidth: isLargeScreen ? '100%' : '30%',
            maxHeight: '48px',
          }}
          variant="outlined"
        >
          New customer
        </Button>
      </Box>
      {!isMobile ? (
        <>
          <CustomersTable
            emptyRows={emptyRows}
            handleRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy as keyof Partial<CustomerType>}
            visibleRows={visibleRows as Partial<CustomerType>[]}
          />
          <TablePagination
            component="div"
            count={rows.length}
            labelRowsPerPage=""
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 100]}
          />
        </>
      ) : (
        <MobileCustomersAccordion rows={rows} />
      )}
    </Box>
  );
};
export default CustomersView;

import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { SyntheticEvent, useState } from 'react';
import { format } from 'date-fns';
import { Chevron } from '../../assets/icons';
import { CustomerType } from '../../interfaces';

const StyledAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <Box sx={{ height: '24px' }}>
        <Chevron />
      </Box>
    }
    {...props}
  />
))(() => ({
  background: '#FFF',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: 1,
  },
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: 2,
  borderTop: '1px solid',
  borderColor: '#F4F4F4',
  marginLeft: 2,
}));
interface CustomerDetailsProps {
  data?: string;
  label: string;
}
const CustomerDetails = ({ data, label }: CustomerDetailsProps) => {
  return (
    <Box sx={{ flexDirection: 'row', display: 'flex', marginY: 2 }}>
      <Typography sx={{ width: '33%', flexShrink: 0, fontWeight: 'bold' }}>{label}</Typography>
      <Typography sx={{ color: 'text.priamry', marginLeft: 1 }}>{data}</Typography>
    </Box>
  );
};
interface CustomizedAccordionsProps {
  rows: Partial<CustomerType>[];
}
const MobileCustomersAccordion = ({ rows }: CustomizedAccordionsProps) => {
  const [expanded, setExpanded] = useState<string | false>('panel1');

  const handleChange = (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {rows.map((customer, index) => {
        return (
          <StyledAccordion
            expanded={expanded === `panel${index}`}
            onChange={handleChange(`panel${index}`)}
            sx={{ borderRadius: index === 0 ? '8px 8px 0px 0px' : null }}
          >
            <StyledAccordionSummary
              aria-controls={`panel${index}-content`}
              id="panel1d-header"
              sx={{
                background: expanded === `panel${index}` ? 'rgba(101, 63, 254, 0.10)' : '#FFF',
                borderRadius: index === 0 ? '8px 8px 0px 0px' : null,
              }}
            >
              <Typography sx={{ width: '33%', flexShrink: 0 }}>Name</Typography>
              <Typography sx={{ color: 'text.primary', marginLeft: 2 }}>
                {customer.companyName}
              </Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails sx={{ paddingY: 0 }}>
              <CustomerDetails data={customer.email} label="Email" />
              <CustomerDetails
                data={format(new Date(customer.createdAt ?? ''), 'dd/MM/yyyy')}
                label="Joining date"
              />
            </StyledAccordionDetails>
          </StyledAccordion>
        );
      })}
    </div>
  );
};
export default MobileCustomersAccordion;

import { Route, Routes } from 'react-router-dom';
import CustomerDetails from './Customers/[id]';
import AddNewCustomerView from './Customers/add-new-customer';
import AddNewVideoView from './Customers/add-new-video';
import Customers from './Customers';
import CustomerVideoDetailsView from './Customers/[videoId]';
import Dashboard from './Dashboard';
import VideoDetailsView from './Videos/[videoId]';
import Videos from './Videos';
import Integrations from './Integrations';
import Referrals from './Referrals';
import ReferralDetails from './Referrals/[id]';
import AddNewReferral from '../components/referral/AddNewReferral';

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<Dashboard />} path="/" />
      <Route element={<Customers />} path="/customers" />
      <Route element={<AddNewCustomerView />} path="/customers/add-new-customer" />
      <Route element={<CustomerDetails />} path="/customers/:id" />
      <Route element={<AddNewVideoView />} path="/customers/:id/add-new-video" />
      <Route element={<CustomerVideoDetailsView />} path="/customers/:id/:videoId" />
      <Route element={<VideoDetailsView />} path="/videos/:videoId" />
      <Route element={<Videos />} path="/videos" />
      <Route element={<Referrals />} path="/referrals" />
      <Route element={<ReferralDetails />} path="/referrals/:id" />
      <Route element={<AddNewReferral />} path="/referrals/add-new-referral" />
      <Route element={<Integrations />} path="/integrations" />
    </Routes>
  );
};

export default AppRouter;

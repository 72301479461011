import { useNavigate, useParams } from 'react-router-dom';
import { Box, ButtonBase, Typography } from '@mui/material';
import React from 'react';
import { useMovieQuery } from '../../hooks/api/movie/movie.generated';
import { BackArrowIcon } from '../../assets/icons';
import { VideoDetails } from '../../components';

const VideoDetailsView = () => {
  const { videoId } = useParams();
  const navigate = useNavigate();

  const { data } = useMovieQuery({ variables: { id: videoId ?? '' } });

  const movieData = data?.movie;
  return (
    <Box>
      <ButtonBase
        onClick={() => navigate('/videos')}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          cursor: 'pointer',
        }}
      >
        <BackArrowIcon />
        <Typography color="secondary" fontSize={24} mt="3px">
          Videos
        </Typography>
      </ButtonBase>
      <VideoDetails movieData={movieData} />
    </Box>
  );
};

export default VideoDetailsView;

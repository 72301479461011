import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerDetailsTabEnum } from '../../types';

interface HomeTopPanelProps {
  currentTab: CustomerDetailsTabEnum;
  onEditClick: () => void;
  isEditable: boolean;
  customerName?: string;
}

const CustomerDetailsTopPanel: FC<HomeTopPanelProps> = ({
  currentTab,
  onEditClick,
  isEditable,
  customerName = 'No customer name',
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const flexDirectionValue = isLargeScreen ? 'row' : 'column';

  const handleNewVideoCreate = () => navigate(`add-new-video`);

  return (
    <Box
      display="flex"
      flexDirection={flexDirectionValue}
      justifyContent="space-between"
      mb={6}
      mt="59px"
      width="100%"
    >
      <Typography fontSize={36} fontWeight={700}>
        {customerName}
      </Typography>
      {currentTab === CustomerDetailsTabEnum.CustomerInformation && !isEditable && (
        <Button
          color="secondary"
          onClick={onEditClick}
          style={{
            padding: isLargeScreen ? '0 90px' : '10px 20px',
            textTransform: 'none',
            maxWidth: isLargeScreen ? '100%' : '30%',
          }}
          variant="outlined"
        >
          Edit
        </Button>
      )}
      {currentTab === CustomerDetailsTabEnum.Video && (
        <Button
          color="secondary"
          onClick={handleNewVideoCreate}
          style={{
            padding: isLargeScreen ? '0 90px' : '10px 20px',
            textTransform: 'none',
            maxWidth: isLargeScreen ? '100%' : '30%',
          }}
          variant="outlined"
        >
          New video
        </Button>
      )}
    </Box>
  );
};

export default CustomerDetailsTopPanel;

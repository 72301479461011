import {
  CustomerType,
  Order,
  MovieType,
  ReferralCodeType,
  ReferralCodeUsageType,
} from '../interfaces';

export function createData(
  id?: string,
  email?: string,
  moviesCount?: number,
  createdAt?: string,
  companyName?: string,
): Partial<CustomerType> {
  return {
    id,
    email,
    moviesCount,
    createdAt,
    companyName,
  };
}

export function createMovieData(
  id: string,
  name: string,
  url: string,
  numberOfViews: number,
  numberOfClicks: number,
  viewsInFull: number,
  ctaClicks: number,
  isActive: boolean,
): Partial<MovieType> {
  return {
    id,
    name,
    url,
    numberOfViews,
    numberOfClicks,
    viewsInFull,
    ctaClicks,
    isActive,
  };
}

export function createReferralCodeData(
  id: string,
  name: string,
  code: string,
  note: string,
  createdAt: string,
  isActive: boolean,
): Partial<ReferralCodeType> {
  return {
    id,
    name,
    code,
    note,
    createdAt,
    isActive,
  };
}

export function createReferralCodeUsageData(
  id: string,
  name: string,
  email: string,
  role: string,
  createdAt: string,
): Partial<ReferralCodeUsageType> {
  return {
    id,
    name,
    email,
    role,
    createdAt,
  };
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number): T[] {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

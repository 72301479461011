import { Box, Button, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import BackButton from '../BackButton';
import { PhoneNumberInput, TextInput } from '../Inputs';
import { CustomerFieldsEnum } from '../../types';
import { useCreateCustomerMutation } from '../../hooks/api/createCustomer/createCustomer.generated';
import { addNewCustomerSchema } from './addNewCustomerSchema';
import { CustomersDocument } from '../../hooks/api/customers/customers.generated';

interface AddNewCustomerValues {
  [CustomerFieldsEnum.CompanyName]: string;
  [CustomerFieldsEnum.TaxId]: string;
  [CustomerFieldsEnum.Country]: string;
  [CustomerFieldsEnum.City]: string;
  [CustomerFieldsEnum.PostalCode]: string;
  [CustomerFieldsEnum.Address]: string;
  [CustomerFieldsEnum.Email]: string;
  [CustomerFieldsEnum.PhoneNumber]: string;
  [CustomerFieldsEnum.Name]: string;
}

const AddNewCustomer = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<AddNewCustomerValues>({
    resolver: yupResolver(addNewCustomerSchema()),
  });

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const maxWidthValue = isLargeScreen ? '368px' : '488px';
  const paperPadding = isLargeScreen ? '32px' : '16px';

  const [createCustomer] = useCreateCustomerMutation({
    refetchQueries: [{ query: CustomersDocument }],
  });
  const onSubmit = async (values: AddNewCustomerValues) => {
    await createCustomer({ variables: { input: { ...values } } });
    await navigate('/customers');
  };
  return (
    <Box>
      <BackButton label="Customers" path="/customers" />
      <Typography fontSize={36} fontWeight={700} pt={4}>
        Add new Customer
      </Typography>
      <Paper style={{ padding: paperPadding }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={12} xs={12}>
              <Typography fontSize="24px" fontWeight={700} pb={6}>
                Basic information
              </Typography>
              <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue}>
                <TextInput
                  {...register(CustomerFieldsEnum.CompanyName)}
                  errors={errors}
                  label="Company name"
                />
                <TextInput {...register(CustomerFieldsEnum.TaxId)} errors={errors} label="Tax ID" />
                <TextInput
                  {...register(CustomerFieldsEnum.Country)}
                  errors={errors}
                  label="Country"
                />
                <TextInput {...register(CustomerFieldsEnum.City)} errors={errors} label="City" />
                <TextInput
                  {...register(CustomerFieldsEnum.PostalCode)}
                  errors={errors}
                  label="Postal Code"
                />
                <TextInput
                  {...register(CustomerFieldsEnum.Address)}
                  errors={errors}
                  label="Address"
                />
              </Box>
            </Grid>
            <Grid item lg={6} sm={12} xs={12}>
              <Typography fontSize="24px" fontWeight={700} pb={6}>
                Contact details
              </Typography>
              <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue}>
                <TextInput {...register(CustomerFieldsEnum.Email)} errors={errors} label="E-mail" />
                <PhoneNumberInput
                  errors={errors}
                  label="Phone number"
                  {...register(CustomerFieldsEnum.PhoneNumber)}
                  onChange={(value) => setValue(CustomerFieldsEnum.PhoneNumber, value)}
                />
                <TextInput {...register(CustomerFieldsEnum.Name)} errors={errors} label="Name" />
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="row" justifyContent="end" pt={6} width="100%">
            <Button
              color="secondary"
              style={{ textTransform: 'none', padding: '13px 76px', fontWeight: '700' }}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default AddNewCustomer;

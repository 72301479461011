import { Box, Typography } from '@mui/material';
import { FC } from 'react';

enum CustomerVideoStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}
interface CustomerVideoStatusPillProps {
  isActive?: boolean;
}
const CustomerVideoStatusPill: FC<CustomerVideoStatusPillProps> = ({ isActive }) => {
  const pillBackgroundColor = isActive ? 'rgba(0, 223, 129, 0.2)' : '#F4F4F4';
  return (
    <Box bgcolor={pillBackgroundColor} borderRadius="22px" px={2} py={1} textAlign="center">
      <Typography fontSize={14}>
        {isActive ? CustomerVideoStatus.Active : CustomerVideoStatus.Inactive}
      </Typography>
    </Box>
  );
};
export default CustomerVideoStatusPill;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type MovieQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type MovieQuery = {
  __typename?: 'Query';
  movie: {
    __typename?: 'MovieType';
    id: string;
    customerId: string;
    name: string;
    companyName?: string | null;
    validFrom: string;
    validUntil: string;
    actorName: string;
    agencyName: string;
    isActive: boolean;
    isEmbed: boolean;
    url: string;
    createdAt: string;
    previewMovie: { __typename?: 'UploadedMovieFileResultType'; fileId: string; name: string };
    fullMovie: { __typename?: 'UploadedMovieFileResultType'; fileId: string; name: string };
    actionConfig: {
      __typename?: 'MovieActionConfigType';
      redirect?: {
        __typename?: 'RedirectActionConfigType';
        buttonLabel: string;
        targetUrl: string;
        openInNewTab?: boolean | null;
      } | null;
      emailInput?: {
        __typename?: 'EmailInputActionConfigType';
        buttonLabelBeforeClick: string;
        buttonLabelAfterClick: string;
        targetUrl: string;
        disclaimer?: string | null;
      } | null;
      copyToClipboard?: {
        __typename?: 'CopyToClipboardActionConfigType';
        label: string;
        labelAfterClick: string;
        value: string;
        iconOn: boolean;
      } | null;
    };
  };
};

export const MovieDocument = gql`
  query movie($id: String!) {
    movie(id: $id) {
      id
      customerId
      name
      companyName
      validFrom
      validUntil
      actorName
      agencyName
      isActive
      isEmbed
      url
      createdAt
      previewMovie {
        fileId
        name
      }
      fullMovie {
        fileId
        name
      }
      actionConfig {
        redirect {
          buttonLabel
          targetUrl
          openInNewTab
        }
        emailInput {
          buttonLabelBeforeClick
          buttonLabelAfterClick
          targetUrl
          disclaimer
        }
        copyToClipboard {
          label
          labelAfterClick
          value
          iconOn
        }
      }
    }
  }
`;

/**
 * __useMovieQuery__
 *
 * To run a query within a React component, call `useMovieQuery` and pass it any options that fit your needs.
 * When your component renders, `useMovieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMovieQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMovieQuery(
  baseOptions: Apollo.QueryHookOptions<MovieQuery, MovieQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MovieQuery, MovieQueryVariables>(MovieDocument, options);
}
export function useMovieLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MovieQuery, MovieQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MovieQuery, MovieQueryVariables>(MovieDocument, options);
}
export type MovieQueryHookResult = ReturnType<typeof useMovieQuery>;
export type MovieLazyQueryHookResult = ReturnType<typeof useMovieLazyQuery>;
export type MovieQueryResult = Apollo.QueryResult<MovieQuery, MovieQueryVariables>;
export function refetchMovieQuery(variables: MovieQueryVariables) {
  return { query: MovieDocument, variables };
}

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type SetReferralCodeStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  isActive: Types.Scalars['Boolean']['input'];
}>;

export type SetReferralCodeStatusMutation = {
  __typename?: 'Mutation';
  setReferralCodeStatus: { __typename?: 'ReferralCodeType'; id: string; isActive: boolean };
};

export const SetReferralCodeStatusDocument = gql`
  mutation SetReferralCodeStatus($id: String!, $isActive: Boolean!) {
    setReferralCodeStatus(id: $id, isActive: $isActive) {
      id
      isActive
    }
  }
`;
export type SetReferralCodeStatusMutationFn = Apollo.MutationFunction<
  SetReferralCodeStatusMutation,
  SetReferralCodeStatusMutationVariables
>;

/**
 * __useSetReferralCodeStatusMutation__
 *
 * To run a mutation, you first call `useSetReferralCodeStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReferralCodeStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReferralCodeStatusMutation, { data, loading, error }] = useSetReferralCodeStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useSetReferralCodeStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetReferralCodeStatusMutation,
    SetReferralCodeStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetReferralCodeStatusMutation, SetReferralCodeStatusMutationVariables>(
    SetReferralCodeStatusDocument,
    options,
  );
}
export type SetReferralCodeStatusMutationHookResult = ReturnType<
  typeof useSetReferralCodeStatusMutation
>;
export type SetReferralCodeStatusMutationResult =
  Apollo.MutationResult<SetReferralCodeStatusMutation>;
export type SetReferralCodeStatusMutationOptions = Apollo.BaseMutationOptions<
  SetReferralCodeStatusMutation,
  SetReferralCodeStatusMutationVariables
>;

import { Controller, useFormContext } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { TextInput } from '../../Inputs';
import { RedirectFormFieldsEnum } from '../../../types';

export interface RedirectConfigFormValues {
  [RedirectFormFieldsEnum.ButtonLabel]: string;
  [RedirectFormFieldsEnum.TargetUrl]: string;
  [RedirectFormFieldsEnum.OpenInNewTab]: boolean;
}

interface RedirectConfigFormProps {
  disabled?: boolean;
}

const RedirectConfigForm: FC<RedirectConfigFormProps> = ({ disabled }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<RedirectConfigFormValues>();

  return (
    <form style={{ display: 'flex', flexDirection: 'column', gap: '14px', paddingTop: '20px' }}>
      <TextInput
        {...register(RedirectFormFieldsEnum.ButtonLabel)}
        disabled={disabled}
        errors={errors}
        label="Button Label"
      />
      <TextInput
        {...register(RedirectFormFieldsEnum.TargetUrl)}
        disabled={disabled}
        errors={errors}
        label="Target URL"
      />
      <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
        <Typography>Open in new tab</Typography>
        <Controller
          name={RedirectFormFieldsEnum.OpenInNewTab}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch checked={value} onChange={onChange} disabled={disabled} />
          )}
        />
        ;
      </Box>
    </form>
  );
};

export default RedirectConfigForm;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type ReferralCodeUsagesQueryVariables = Types.Exact<{
  codeId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type ReferralCodeUsagesQuery = {
  __typename?: 'Query';
  referralCodeUsages: Array<{
    __typename?: 'ReferralCodeUsageType';
    id: string;
    codeId: string;
    name: string;
    email: string;
    role: string;
    createdAt: string;
  }>;
};

export const ReferralCodeUsagesDocument = gql`
  query referralCodeUsages($codeId: String) {
    referralCodeUsages(codeId: $codeId) {
      id
      codeId
      name
      email
      role
      createdAt
    }
  }
`;

/**
 * __useReferralCodeUsagesQuery__
 *
 * To run a query within a React component, call `useReferralCodeUsagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodeUsagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodeUsagesQuery({
 *   variables: {
 *      codeId: // value for 'codeId'
 *   },
 * });
 */
export function useReferralCodeUsagesQuery(
  baseOptions?: Apollo.QueryHookOptions<ReferralCodeUsagesQuery, ReferralCodeUsagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferralCodeUsagesQuery, ReferralCodeUsagesQueryVariables>(
    ReferralCodeUsagesDocument,
    options,
  );
}
export function useReferralCodeUsagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferralCodeUsagesQuery,
    ReferralCodeUsagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferralCodeUsagesQuery, ReferralCodeUsagesQueryVariables>(
    ReferralCodeUsagesDocument,
    options,
  );
}
export type ReferralCodeUsagesQueryHookResult = ReturnType<typeof useReferralCodeUsagesQuery>;
export type ReferralCodeUsagesLazyQueryHookResult = ReturnType<
  typeof useReferralCodeUsagesLazyQuery
>;
export type ReferralCodeUsagesQueryResult = Apollo.QueryResult<
  ReferralCodeUsagesQuery,
  ReferralCodeUsagesQueryVariables
>;
export function refetchReferralCodeUsagesQuery(variables?: ReferralCodeUsagesQueryVariables) {
  return { query: ReferralCodeUsagesDocument, variables };
}

import { useMemo } from 'react';
import { Box, TablePagination } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useReferralCodeUsagesQuery } from '../../hooks/api/referralUsage/referralUsage.generated';
import { createReferralCodeUsageData, getComparator, stableSort } from '../utils';
import { ReferralCodeUsageType } from '../../interfaces';
import { usePagination } from '../../hooks';
import ReferralDetailsTable from './ReferralDetailsTable';

const ReferralDetailsView = () => {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleRequestSort,
  } = usePagination();

  const { id } = useParams();

  const { data: referralCodeUsageData } = useReferralCodeUsagesQuery({
    variables: {
      codeId: id ?? '',
    },
    fetchPolicy: 'no-cache',
  });

  const rows =
    referralCodeUsageData?.referralCodeUsages.map((code) =>
      createReferralCodeUsageData(
        code.id ?? '',
        code.name ?? '',
        code.email ?? '',
        code.role ?? '',
        code.createdAt ?? '',
      ),
    ) || [];

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows as any[], getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ) as Partial<ReferralCodeUsageType>,
    [order, orderBy, page, rowsPerPage, rows],
  );

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <ReferralDetailsTable
        emptyRows={emptyRows}
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy as keyof Partial<ReferralCodeUsageType>}
        visibleRows={visibleRows as any}
      />
      <TablePagination
        component="div"
        count={rows.length}
        labelRowsPerPage=""
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Box>
  );
};
export default ReferralDetailsView;

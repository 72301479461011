import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type SignMovieUploadCredentialsMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  size: Types.Scalars['Int']['input'];
  mimeType: Types.Scalars['String']['input'];
}>;

export type SignMovieUploadCredentialsMutation = {
  __typename?: 'Mutation';
  signMovieUploadCredentials: {
    __typename?: 'UploadCredentialsType';
    fieldsJson: string;
    fileId: string;
    url: string;
  };
};

export const SignMovieUploadCredentialsDocument = gql`
  mutation signMovieUploadCredentials($name: String!, $size: Int!, $mimeType: String!) {
    signMovieUploadCredentials(name: $name, mimeType: $mimeType, size: $size) {
      fieldsJson
      fileId
      url
    }
  }
`;
export type SignMovieUploadCredentialsMutationFn = Apollo.MutationFunction<
  SignMovieUploadCredentialsMutation,
  SignMovieUploadCredentialsMutationVariables
>;

/**
 * __useSignMovieUploadCredentialsMutation__
 *
 * To run a mutation, you first call `useSignMovieUploadCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignMovieUploadCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signMovieUploadCredentialsMutation, { data, loading, error }] = useSignMovieUploadCredentialsMutation({
 *   variables: {
 *      name: // value for 'name'
 *      size: // value for 'size'
 *      mimeType: // value for 'mimeType'
 *   },
 * });
 */
export function useSignMovieUploadCredentialsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignMovieUploadCredentialsMutation,
    SignMovieUploadCredentialsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SignMovieUploadCredentialsMutation,
    SignMovieUploadCredentialsMutationVariables
  >(SignMovieUploadCredentialsDocument, options);
}
export type SignMovieUploadCredentialsMutationHookResult = ReturnType<
  typeof useSignMovieUploadCredentialsMutation
>;
export type SignMovieUploadCredentialsMutationResult =
  Apollo.MutationResult<SignMovieUploadCredentialsMutation>;
export type SignMovieUploadCredentialsMutationOptions = Apollo.BaseMutationOptions<
  SignMovieUploadCredentialsMutation,
  SignMovieUploadCredentialsMutationVariables
>;

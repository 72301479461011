import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import BackButton from '../BackButton';
import { DateInput, DragAndDropInput, TextInput } from '../Inputs';
import { MovieConfigSelectType, MovieFieldsEnum } from '../../types';
import { useCreateMovieMutation } from '../../hooks/api/createMovie/createMovie.generated';
import { CustomerMoviesDocument } from '../../hooks/api/customerMovies/customerMovies.generated';
import { MoviesDocument } from '../../hooks/api/movies/movies.generated';
import { addNewVideoSchema } from './addNewVideoSchema';
import { MovieActionConfigInputType, UploadedMovieFileInputType } from '../../interfaces';
import RedirectConfigForm from './ActionConfigForms/RedirectConfigForm';
import EmailInputConfigForm from './ActionConfigForms/EmailInputConfigForm';
import { appConfig } from '../../app-config';
import CopyToClipboardConfigForm from './ActionConfigForms/CopyToClipboardConfigForm';

// TODO find a better place for this function
export const c2cardBaseUrl = `${appConfig.cdnBaseUrl}/c2-card/`;

interface AddNewVideoProps {
  customerId?: string;
}

interface AddNewVideoValues {
  [MovieFieldsEnum.Name]: string;
  [MovieFieldsEnum.ValidFrom]: string;
  [MovieFieldsEnum.ValidUntil]: string;
  [MovieFieldsEnum.ActorName]: string;
  [MovieFieldsEnum.AgencyName]: string;
  [MovieFieldsEnum.Url]: string;
  [MovieFieldsEnum.IsActive]: boolean;
  [MovieFieldsEnum.IsEmbed]: boolean;
  [MovieFieldsEnum.PreviewMovie]: UploadedMovieFileInputType;
  [MovieFieldsEnum.FullMovie]: UploadedMovieFileInputType;
  [MovieFieldsEnum.ActionConfig]: MovieActionConfigInputType;
}

const AddNewVideo: FC<AddNewVideoProps> = ({ customerId }) => {
  const navigate = useNavigate();
  const [selectedAction, setSelectedAction] = useState<MovieConfigSelectType>(null);
  const methods = useForm<AddNewVideoValues>({
    defaultValues: { isActive: false },
    resolver: yupResolver(addNewVideoSchema(selectedAction)),
  });

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const maxWidthValue = isLargeScreen ? '368px' : '488px';
  const paperPadding = isLargeScreen ? '32px' : '16px';

  const [createMovie] = useCreateMovieMutation({
    refetchQueries: [
      { query: CustomerMoviesDocument, variables: { customerId } },
      { query: MoviesDocument },
    ],
  });

  useEffect(() => {
    if (methods.watch(MovieFieldsEnum.IsEmbed)) {
      methods.setValue(MovieFieldsEnum.Url, c2cardBaseUrl, {
        shouldValidate: true,
      });
    } else {
      methods.setValue(MovieFieldsEnum.Url, '');
    }
  }, [methods.watch(MovieFieldsEnum.IsEmbed)]);

  const onSubmit = async (values: AddNewVideoValues) => {
    await createMovie({
      variables: {
        input: {
          customerId: customerId ?? '',
          ...values,
        },
      },
    });
    await navigate(`/customers/${customerId}`);
  };

  useEffect(() => {
    if (selectedAction === 'redirect') {
      methods.resetField('actionConfig.emailInput', undefined);
    } else if (selectedAction === 'emailInput') {
      methods.resetField('actionConfig.redirect', undefined);
    } else if (selectedAction === 'copyToClipboard') {
      methods.resetField('actionConfig.copyToClipboard', undefined);
    }
  }, [selectedAction, methods]);
  return (
    <Box>
      <BackButton label="Customers" path="/customers" />
      <Typography fontSize={36} fontWeight={700} pb={6} pt={4}>
        New video
      </Typography>
      <Paper style={{ padding: paperPadding }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item lg={6} sm={12} xs={12}>
                <Typography fontSize="24px" fontWeight={700} pb={6}>
                  Basic information
                </Typography>
                <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue}>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography>Active video</Typography>
                    <Switch {...methods.register(MovieFieldsEnum.IsActive)} color="secondary" />
                  </Box>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography>Embed mode (c2 card)</Typography>
                    <Switch {...methods.register(MovieFieldsEnum.IsEmbed)} color="secondary" />
                  </Box>
                  <TextInput
                    {...methods.register(MovieFieldsEnum.Name)}
                    errors={methods.formState.errors}
                    label="Video name"
                  />
                  <Box display="flex" flexDirection="row" gap={3}>
                    <DateInput
                      control={methods.control}
                      errors={methods.formState.errors}
                      label="Valid from"
                      name={MovieFieldsEnum.ValidFrom}
                    />
                    <DateInput
                      control={methods.control}
                      errors={methods.formState.errors}
                      label="Valid until"
                      name={MovieFieldsEnum.ValidUntil}
                    />
                  </Box>
                  <TextInput
                    {...methods.register(MovieFieldsEnum.ActorName)}
                    errors={methods.formState.errors}
                    label="Actor’s name and surname"
                  />
                  <TextInput
                    {...methods.register(MovieFieldsEnum.AgencyName)}
                    errors={methods.formState.errors}
                    label="Agency name"
                  />
                  <TextInput
                    {...methods.register(MovieFieldsEnum.Url)}
                    errors={methods.formState.errors}
                    label="Widget url"
                    disabled={methods.watch(MovieFieldsEnum.IsEmbed)}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <Typography fontSize="24px" fontWeight={700} pb={6}>
                  Videos
                </Typography>
                <Box display="flex" flexDirection="column" gap={3}>
                  <DragAndDropInput
                    {...methods.register(MovieFieldsEnum.PreviewMovie)}
                    errors={methods.formState.errors}
                    label="Preview video"
                    onFileUploadComplete={(value) =>
                      methods.setValue(MovieFieldsEnum.PreviewMovie, value)
                    }
                  />
                  <DragAndDropInput
                    {...methods.register(MovieFieldsEnum.FullMovie)}
                    errors={methods.formState.errors}
                    label="Full video"
                    onFileUploadComplete={(value) =>
                      methods.setValue(MovieFieldsEnum.FullMovie, value)
                    }
                  />
                  <Box>
                    <FormControl fullWidth margin="normal">
                      <Typography pb={1}>Action Type</Typography>
                      <Select
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Type' }}
                        onChange={(e) => setSelectedAction(e.target.value as MovieConfigSelectType)}
                        renderValue={selectedAction !== null ? undefined : () => 'Select option'}
                        value={selectedAction}
                      >
                        <MenuItem value="redirect">Redirect</MenuItem>
                        <MenuItem value="emailInput">Email</MenuItem>
                        <MenuItem value="copyToClipboard">Copy to clipboard</MenuItem>
                      </Select>
                    </FormControl>
                    {selectedAction === 'redirect' && <RedirectConfigForm />}
                    {selectedAction === 'emailInput' && <EmailInputConfigForm />}
                    {selectedAction === 'copyToClipboard' && <CopyToClipboardConfigForm />}
                    {methods.formState.errors.actionConfig && (
                      <Typography color="error" fontSize={13}>
                        {methods.formState.errors.actionConfig.message}
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue} />
              </Grid>
            </Grid>
            <Box display="flex" flexDirection="row" justifyContent="end" pt={6} width="100%">
              <Button
                color="secondary"
                style={{ textTransform: 'none', padding: '13px 76px', fontWeight: '700' }}
                type="submit"
                variant="contained"
              >
                Save
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Paper>
    </Box>
  );
};

export default AddNewVideo;

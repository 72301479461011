import { Card, CardContent, Typography } from '@mui/material';

interface CardData {
  label: string;
  data: number | string;
  email?: string;
}

const DashboardCard = ({ label, data, email }: CardData) => {
  return (
    <Card component="div" sx={{ borderRadius: '8px', height: '100%' }} variant="outlined">
      <CardContent>
        <Typography gutterBottom sx={{ fontSize: 24 }}>
          {label}
        </Typography>
        <Typography component="div" sx={{ fontSize: 32, fontWeight: 'bold' }}>
          {data}
        </Typography>
        {email && (
          <Typography gutterBottom sx={{ fontSize: 20 }}>
            {email}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default DashboardCard;

import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode } from 'react';
import { ApolloClient, ApolloProvider, concat, HttpLink, InMemoryCache } from '@apollo/client';
import { appConfig } from '../app-config';

interface GraphQLProviderProps {
  children: ReactNode;
}

export const typePolicies = {
  JobOrderApplicationType: {
    keyFields: ['applicationId'],
  },
};

const createApolloClient = (getToken: () => Promise<string>) => {
  const httpLink = new HttpLink({
    uri: appConfig.graphqlApiUrl,
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies,
    }),
    link: concat(
      setContext(async (context) => {
        const authorizationToken = await getToken().catch(console.error);

        if (!authorizationToken) {
          return context;
        }

        return {
          ...context,
          uri: `${appConfig.graphqlApiUrl}?operation=${context.operationName}`,
          headers: {
            authorization: `Bearer ${authorizationToken}`,
          },
        };
      }),
      httpLink,
    ),
  });
};

export const GraphQLProvider = ({ children }: GraphQLProviderProps) => {
  const { getAccessTokenSilently } = useAuth0();

  return (
    <ApolloProvider client={createApolloClient(getAccessTokenSilently)}>{children}</ApolloProvider>
  );
};

import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomerDetailsTabEnum } from '../../types';
import { useCustomerQuery } from '../../hooks/api/customer/customer.generated';
import { CustomerDetailsTopPanel, CustomerVideosView } from '../../components';
import CustomerInformation from '../../components/CustomerDetails/CustomerDetailsTabs/CustomerInformation';
import { CustomTab } from '../../components/CustomerDetails/CustomerDetailsTabs/CustomTab';
import BackButton from '../../components/BackButton';

const CustomerDetails = () => {
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState(CustomerDetailsTabEnum.Video);
  const [isEditable, setIsEditable] = useState(false);

  const { data: customerData } = useCustomerQuery({ variables: { id: id ?? '' } });

  const handleEditClick = () => setIsEditable(true);

  const handleChange = (event: React.SyntheticEvent, newValue: CustomerDetailsTabEnum) => {
    setCurrentTab(newValue);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <BackButton label="Customers" path="/customers" />
      <CustomerDetailsTopPanel
        currentTab={currentTab}
        customerName={customerData?.customer.companyName}
        isEditable={isEditable}
        onEditClick={handleEditClick}
      />
      <Tabs
        aria-label="secondary tabs example"
        indicatorColor="secondary"
        onChange={handleChange}
        style={{ textTransform: 'none', color: '#653FFE' }}
        textColor="secondary"
        value={currentTab}
      >
        <Tab
          label="Videos"
          style={{ textTransform: 'none', fontWeight: '700' }}
          value={CustomerDetailsTabEnum.Video}
        />
        <Tab
          label="Customer information"
          style={{ textTransform: 'none', fontWeight: '700' }}
          value={CustomerDetailsTabEnum.CustomerInformation}
        />
      </Tabs>
      <CustomTab currentTab={currentTab} tabToShow={CustomerDetailsTabEnum.Video}>
        <CustomerVideosView />
      </CustomTab>
      <CustomTab currentTab={currentTab} tabToShow={CustomerDetailsTabEnum.CustomerInformation}>
        <CustomerInformation
          closeEditMode={() => setIsEditable(false)}
          customerData={customerData?.customer}
          customerId={id}
          isEditable={isEditable}
        />
      </CustomTab>
    </Box>
  );
};

export default CustomerDetails;

import { Box, Paper, useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';
import { CustomerDetailsTabEnum } from '../../../types';

interface CustomTabProps {
  currentTab: CustomerDetailsTabEnum;
  tabToShow: CustomerDetailsTabEnum;
  children: ReactNode;
}

export const CustomTab: FC<CustomTabProps> = ({ currentTab, children, tabToShow }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const tabPadding = isLargeScreen ? 8 : 4;
  const isTabVisible = currentTab === tabToShow;
  return (
    <Paper hidden={!isTabVisible}>
      <Box mt={6} p={tabPadding}>
        {children}
      </Box>
    </Paper>
  );
};

import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import CustomersTableHead from './CustomersTableHead';
import { CustomerType, Order } from '../../interfaces';

interface CustomersTableProps {
  handleRequestSort: (event: MouseEvent<unknown>, property: keyof Partial<CustomerType>) => void;
  order: Order;
  orderBy: keyof Partial<CustomerType>;
  visibleRows: Partial<CustomerType>[];
  emptyRows: number;
}

const CustomersTable = ({
  handleRequestSort,
  order,
  orderBy,
  visibleRows,
  emptyRows,
}: CustomersTableProps) => {
  const navigate = useNavigate();
  const handleOnRowClick = (id: string | undefined) => navigate(`${id}`);
  return (
    <TableContainer sx={{ borderRadius: '8px 8px 0px 0px' }}>
      <Table aria-labelledby="tableTitle" sx={{ minWidth: 750 }}>
        <CustomersTableHead onRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
        <TableBody>
          {visibleRows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                key={row.id}
                hover
                onClick={() => handleOnRowClick(row.id)}
                role="checkbox"
                sx={{ cursor: 'pointer', background: '#FFF' }}
                tabIndex={-1}
              >
                <TableCell component="th" id={labelId} scope="row" sx={{ fontSize: '16px' }}>
                  {row.companyName}
                </TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{row.email}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>{row.moviesCount}</TableCell>
                <TableCell sx={{ fontSize: '16px' }}>
                  {format(new Date(row.createdAt ?? ''), 'dd/MM/yyyy')}
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 53 * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default CustomersTable;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type UpdateMovieMutationVariables = Types.Exact<{
  input: Types.UpdateMovieInputType;
}>;

export type UpdateMovieMutation = {
  __typename?: 'Mutation';
  updateMovie: {
    __typename?: 'MovieType';
    id: string;
    customerId: string;
    name: string;
    validFrom: string;
    validUntil: string;
    actorName: string;
    agencyName: string;
    isActive: boolean;
    isEmbed: boolean;
    previewMovie: { __typename?: 'UploadedMovieFileResultType'; fileId: string; name: string };
    fullMovie: { __typename?: 'UploadedMovieFileResultType'; fileId: string; name: string };
    actionConfig: {
      __typename?: 'MovieActionConfigType';
      redirect?: {
        __typename?: 'RedirectActionConfigType';
        buttonLabel: string;
        targetUrl: string;
        openInNewTab?: boolean | null;
      } | null;
      emailInput?: {
        __typename?: 'EmailInputActionConfigType';
        buttonLabelBeforeClick: string;
        buttonLabelAfterClick: string;
        targetUrl: string;
        disclaimer?: string | null;
      } | null;
      copyToClipboard?: {
        __typename?: 'CopyToClipboardActionConfigType';
        label: string;
        labelAfterClick: string;
        value: string;
        iconOn: boolean;
      } | null;
    };
  };
};

export const UpdateMovieDocument = gql`
  mutation updateMovie($input: UpdateMovieInputType!) {
    updateMovie(input: $input) {
      id
      customerId
      name
      validFrom
      validUntil
      actorName
      agencyName
      isActive
      isEmbed
      previewMovie {
        fileId
        name
      }
      fullMovie {
        fileId
        name
      }
      actionConfig {
        redirect {
          buttonLabel
          targetUrl
        }
        emailInput {
          buttonLabelBeforeClick
          buttonLabelAfterClick
          targetUrl
          disclaimer
        }
        copyToClipboard {
          label
          labelAfterClick
          value
          iconOn
        }
      }
    }
  }
`;
export type UpdateMovieMutationFn = Apollo.MutationFunction<
  UpdateMovieMutation,
  UpdateMovieMutationVariables
>;

/**
 * __useUpdateMovieMutation__
 *
 * To run a mutation, you first call `useUpdateMovieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMovieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMovieMutation, { data, loading, error }] = useUpdateMovieMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMovieMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMovieMutation, UpdateMovieMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMovieMutation, UpdateMovieMutationVariables>(
    UpdateMovieDocument,
    options,
  );
}
export type UpdateMovieMutationHookResult = ReturnType<typeof useUpdateMovieMutation>;
export type UpdateMovieMutationResult = Apollo.MutationResult<UpdateMovieMutation>;
export type UpdateMovieMutationOptions = Apollo.BaseMutationOptions<
  UpdateMovieMutation,
  UpdateMovieMutationVariables
>;

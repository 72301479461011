import { useFormContext } from 'react-hook-form';
import React, { FC } from 'react';
import { TextInput } from '../../Inputs';
import { EmailInputFormFieldsEnum } from '../../../types';

export interface EmailInputConfigFormValues {
  [EmailInputFormFieldsEnum.ButtonLabelBeforeClick]?: string;
  [EmailInputFormFieldsEnum.ButtonLabelAfterClick]?: string;
  [EmailInputFormFieldsEnum.TargetUrl]?: string;
  [EmailInputFormFieldsEnum.Disclaimer]?: string | null;
}

interface EmailInputConfigFormProps {
  disabled?: boolean;
}

const EmailInputConfigForm: FC<EmailInputConfigFormProps> = ({ disabled }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<EmailInputConfigFormValues>();

  return (
    <form style={{ display: 'flex', flexDirection: 'column', gap: '14px', paddingTop: '20px' }}>
      <TextInput
        {...register(EmailInputFormFieldsEnum.ButtonLabelBeforeClick)}
        disabled={disabled}
        errors={errors}
        label="Button label before click"
      />
      <TextInput
        {...register(EmailInputFormFieldsEnum.ButtonLabelAfterClick)}
        disabled={disabled}
        errors={errors}
        label="Button label after click"
      />
      <TextInput
        {...register(EmailInputFormFieldsEnum.TargetUrl)}
        disabled={disabled}
        errors={errors}
        label="Target URL"
      />
      <TextInput
        {...register(EmailInputFormFieldsEnum.Disclaimer)}
        disabled={disabled}
        errors={errors}
        label="Disclaimer"
      />
    </form>
  );
};

export default EmailInputConfigForm;

import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import { ReferralDetailsTopPanel } from '../../components/ReferralDetails';
import { useReferralCodeQuery } from '../../hooks/api/referralCode/referralCode.generated';
import ReferralDetailsView from '../../components/ReferralDetails/ReferralDetailsView';

const ReferralDetails = () => {
  const { id } = useParams();

  const { data: codeData } = useReferralCodeQuery({ variables: { id: id ?? '' } });

  return (
    <Box sx={{ width: '100%' }}>
      <BackButton label="Referrals" path="/referrals" />
      <ReferralDetailsTopPanel
        id={codeData?.referralCode.id}
        note={codeData?.referralCode.note}
        referralName={codeData?.referralCode.code}
      />
      <ReferralDetailsView />
    </Box>
  );
};

export default ReferralDetails;

import { ChangeEvent, MouseEvent, useState } from 'react';
import {
  CustomerType,
  MovieType,
  Order,
  ReferralCodeType,
  ReferralCodeUsageType,
} from '../interfaces';

type HeadKeys = CustomerType & MovieType & ReferralCodeType & ReferralCodeUsageType;

export const usePagination = () => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof HeadKeys>('createdAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event: MouseEvent<unknown>, property: keyof HeadKeys) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleRequestSort,
  };
};

import { Controller, useFormContext } from 'react-hook-form';
import React, { FC } from 'react';
import { Box, Switch, Typography } from '@mui/material';
import { TextInput } from '../../Inputs';
import { CopyToClipboardFormFieldsEnum } from '../../../types';

export interface CopyToClipboardConfigFormValues {
  [CopyToClipboardFormFieldsEnum.Label]: string;
  [CopyToClipboardFormFieldsEnum.LabelAfterClick]: string;
  [CopyToClipboardFormFieldsEnum.Value]: string;
  [CopyToClipboardFormFieldsEnum.IconOn]: boolean;
}

interface CopyToClipboardConfigFormProps {
  disabled?: boolean;
}

const CopyToClipboardConfigForm: FC<CopyToClipboardConfigFormProps> = ({ disabled }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<CopyToClipboardConfigFormValues>();

  return (
    <form style={{ display: 'flex', flexDirection: 'column', gap: '14px', paddingTop: '20px' }}>
      <TextInput
        {...register(CopyToClipboardFormFieldsEnum.Label)}
        disabled={disabled}
        errors={errors}
        label="Button Label"
      />
      <TextInput
        {...register(CopyToClipboardFormFieldsEnum.LabelAfterClick)}
        disabled={disabled}
        errors={errors}
        label="Button Label after click"
      />
      <TextInput
        {...register(CopyToClipboardFormFieldsEnum.Value)}
        disabled={disabled}
        errors={errors}
        label="Value to be copied to clipboard"
      />
      <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
        <Typography>Show icon</Typography>
        <Controller
          name={CopyToClipboardFormFieldsEnum.IconOn}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Switch checked={value} onChange={onChange} disabled={disabled} />
          )}
        />
        ;
      </Box>
    </form>
  );
};

export default CopyToClipboardConfigForm;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type ReferralCodesQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ReferralCodesQuery = {
  __typename?: 'Query';
  referralCodes: Array<{
    __typename?: 'ReferralCodeType';
    id: string;
    name: string;
    code: string;
    note: string;
    createdAt: string;
    isActive: boolean;
  }>;
};

export const ReferralCodesDocument = gql`
  query referralCodes {
    referralCodes {
      id
      name
      code
      note
      createdAt
      isActive
    }
  }
`;

/**
 * __useReferralCodesQuery__
 *
 * To run a query within a React component, call `useReferralCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferralCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<ReferralCodesQuery, ReferralCodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferralCodesQuery, ReferralCodesQueryVariables>(
    ReferralCodesDocument,
    options,
  );
}
export function useReferralCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReferralCodesQuery, ReferralCodesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferralCodesQuery, ReferralCodesQueryVariables>(
    ReferralCodesDocument,
    options,
  );
}
export type ReferralCodesQueryHookResult = ReturnType<typeof useReferralCodesQuery>;
export type ReferralCodesLazyQueryHookResult = ReturnType<typeof useReferralCodesLazyQuery>;
export type ReferralCodesQueryResult = Apollo.QueryResult<
  ReferralCodesQuery,
  ReferralCodesQueryVariables
>;
export function refetchReferralCodesQuery(variables?: ReferralCodesQueryVariables) {
  return { query: ReferralCodesDocument, variables };
}

import * as yup from 'yup';
import { ReferralCodeEnum } from '../../types';

export const addNewReferralSchema = () =>
  yup.object().shape({
    [ReferralCodeEnum.Name]: yup
      .string()
      .max(100, 'City cannot be longer than 100 characters')
      .required('Name field is required'),
    [ReferralCodeEnum.Code]: yup
      .string()
      .max(100, 'Name cannot be longer than 100 characters')
      .required('Code field is required'),
    [ReferralCodeEnum.Note]: yup.string().nullable().notRequired(),
  });

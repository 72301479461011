import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { MouseEvent } from 'react';
import { MovieType, Order } from '../../../interfaces';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Partial<MovieType>;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
  },
  {
    id: 'url',
    numeric: false,
    disablePadding: false,
    label: 'URL Address',
  },
  {
    id: 'numberOfViews',
    numeric: false,
    disablePadding: false,
    label: 'Number of views',
  },
  {
    id: 'numberOfClicks',
    numeric: false,
    disablePadding: false,
    label: 'Number of clicks',
  },
  {
    id: 'viewsInFull',
    numeric: false,
    disablePadding: false,
    label: 'Views in full',
  },
  {
    id: 'ctaClicks',
    numeric: false,
    disablePadding: false,
    label: 'CTA clicks',
  },
  {
    id: 'isActive',
    numeric: false,
    disablePadding: false,
    label: 'Status',
  },
];

interface VideosTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof Partial<MovieType>) => void;
  order: Order;
  orderBy: string;
}

function CustomerVideosTableHead(props: VideosTableHeadProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Partial<MovieType>) => (event: MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ background: 'rgba(101, 63, 254, 0.10)' }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontWeight: 'bold', fontSize: '16px' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default CustomerVideosTableHead;

import { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Box,
  styled,
  Typography,
} from '@mui/material';
import {
  CountryCallingCode,
  getCountries,
  getCountryCallingCode,
  NationalNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';

const prefixOptions = getCountries().map((country) => {
  const countryPrefix = getCountryCallingCode(country);
  return {
    value: countryPrefix,
    label: `${country} (+${countryPrefix})`,
    country,
  };
});

interface PhoneNumberInputProps {
  name: string;
  label?: string;
  initialValue?: string;
  errors?: any;
  disabled?: boolean;
  onChange: (phoneNumber: string) => void;
  omitPrefixIfEmpty?: boolean;
  defaultPrefix?: string;
  isRequired?: boolean;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  label,
  name,
  initialValue = '',
  errors,
  onChange,
  omitPrefixIfEmpty = false,
  defaultPrefix = '',
  isRequired = false,
  disabled,
}) => {
  const [prefix, setPrefix] = useState<CountryCallingCode>(defaultPrefix);
  const [number, setNumber] = useState<NationalNumber>('');
  const fieldError = errors && errors[name];

  useEffect(() => {
    if (!number && initialValue) {
      try {
        const parsedNumber = parsePhoneNumber(initialValue);
        if (parsedNumber) {
          if (typeof parsedNumber !== 'string' && parsedNumber.nationalNumber !== number) {
            setNumber(parsedNumber.nationalNumber);
          }
          if (typeof parsedNumber !== 'string' && parsedNumber.countryCallingCode !== prefix) {
            setPrefix(parsedNumber.countryCallingCode);
          }
        } else {
          setNumber(initialValue);
        }
      } catch (error) {
        console.error('Error parsing phone number:', error);
      }
    }
  }, [initialValue]);

  const handleChange = (
    type: 'number' | 'prefix',
    value: string | CountryCallingCode | NationalNumber,
  ) => {
    let currentNumber;
    let currentPrefix;
    if (type === 'number') {
      currentNumber = value;
      currentPrefix = prefix;
      setNumber(value as NationalNumber);
    } else {
      currentNumber = number;
      currentPrefix = value;
      setPrefix(value as CountryCallingCode);
    }
    const mergedNumber = `+${currentPrefix}${currentNumber}`;
    onChange(omitPrefixIfEmpty && currentNumber === '' ? '' : mergedNumber);
  };

  return (
    <Box>
      {label && <Typography pb={1}>{label}</Typography>}
      <Box display="flex" gap={2}>
        <FormControl style={{ flex: 1.5 }} variant="outlined">
          <Select
            disabled={disabled}
            error={Boolean(fieldError)}
            onChange={(e) => handleChange('prefix', e.target.value as string)}
            style={{
              backgroundColor: disabled ? '#F4F4F4' : 'white',
            }}
            value={prefix}
          >
            {prefixOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <StyledTextField
          disabled={disabled}
          error={Boolean(fieldError)}
          InputProps={{
            style: {
              backgroundColor: disabled ? '#F4F4F4' : 'white',
            },
          }}
          name={name}
          onChange={(e) => handleChange('number', e.target.value)}
          required={isRequired}
          style={{ flex: 3 }}
          value={number}
          variant="outlined"
        />
      </Box>
      {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
    </Box>
  );
};

export default PhoneNumberInput;

import QRCode from 'react-qr-code';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { DateInput, DragAndDropInput, TextInput } from '../Inputs';
import { MovieConfigSelectType, MovieFieldsEnum } from '../../types';
import { useUpdateMovieMutation } from '../../hooks/api/updateMovie/updateMovie.generated';
import {
  MovieActionConfigInputType,
  MovieType,
  UploadedMovieFileInputType,
  UploadedMovieFileResultType,
} from '../../interfaces';
import { videoDetailsSchema } from './videoDetailsSchema';
import { removeTypenames } from './utils';
import EmailInputConfigForm from '../videos/ActionConfigForms/EmailInputConfigForm';
import RedirectConfigForm from '../videos/ActionConfigForms/RedirectConfigForm';
import { c2cardBaseUrl } from '../videos/AddNewVideo';
import { appConfig } from '../../app-config';
import CopyToClipboardConfigForm from '../videos/ActionConfigForms/CopyToClipboardConfigForm';

export const c2cardUrl = (movieData?: Partial<MovieType>): string =>
  `${c2cardBaseUrl}?${appConfig.isDev ? 'env=dev&' : ''}videoId=${movieData?.id}`;

// TODO move to componenents?
interface PreviewButtonProps {
  url: string;
  title: string;
  handleOnClick: (url: string) => void;
  disabled?: boolean;
  marginLeft?: boolean;
  marginTop?: boolean;
}

// TODO rename
const PreviewButton: FC<PreviewButtonProps> = ({
  url,
  title,
  handleOnClick,
  disabled,
  marginLeft = false,
  marginTop = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      variant="contained"
      color="primary"
      disabled={disabled}
      onClick={() => handleOnClick(url)}
      style={{
        marginLeft: marginLeft && !isMobile ? theme.spacing(2) : 0,
        marginTop: marginTop && isMobile ? theme.spacing(2) : 0,
      }}
    >
      {title}
    </Button>
  );
};

interface VideoDetailsProps {
  movieData?: Partial<MovieType>;
}

interface VideoDetailsValues {
  [MovieFieldsEnum.Name]: string;
  [MovieFieldsEnum.ValidFrom]: string;
  [MovieFieldsEnum.ValidUntil]: string;
  [MovieFieldsEnum.ActorName]: string;
  [MovieFieldsEnum.AgencyName]: string;
  [MovieFieldsEnum.IsActive]: boolean;
  [MovieFieldsEnum.IsEmbed]: boolean;
  [MovieFieldsEnum.Url]: string;
  [MovieFieldsEnum.PreviewMovie]: UploadedMovieFileInputType;
  [MovieFieldsEnum.FullMovie]: UploadedMovieFileInputType;
  [MovieFieldsEnum.ActionConfig]: MovieActionConfigInputType;
}

const VideoDetails: FC<VideoDetailsProps> = ({ movieData }) => {
  const [selectedAction, setSelectedAction] = useState<MovieConfigSelectType>(null);
  const methods = useForm<VideoDetailsValues>({
    resolver: yupResolver(videoDetailsSchema(selectedAction)),
  });

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const [isEditable, setIsEditable] = useState(false);
  const [showQR, setShowQR] = useState(false);

  const maxWidthValue = isLargeScreen ? '368px' : '488px';
  const paperPadding = isLargeScreen ? '32px' : '16px';

  const transformMovieFile = (
    fileData?: UploadedMovieFileResultType | null,
  ): UploadedMovieFileInputType => {
    if (fileData && fileData.fileId && fileData.name) {
      return {
        fileId: fileData.fileId,
        name: fileData.name,
      };
    }
    return {
      fileId: '',
      name: '',
    };
  };

  useEffect(() => {
    if (movieData?.actionConfig?.redirect) {
      setSelectedAction('redirect');
    } else if (movieData?.actionConfig?.emailInput) {
      setSelectedAction('emailInput');
    } else if (movieData?.actionConfig?.copyToClipboard) {
      setSelectedAction('copyToClipboard');
    }

    const defaultValues = {
      [MovieFieldsEnum.Name]: movieData?.name,
      [MovieFieldsEnum.ValidFrom]: movieData?.validFrom,
      [MovieFieldsEnum.ValidUntil]: movieData?.validUntil,
      [MovieFieldsEnum.ActorName]: movieData?.actorName,
      [MovieFieldsEnum.AgencyName]: movieData?.agencyName,
      [MovieFieldsEnum.Url]: movieData?.url,
      [MovieFieldsEnum.IsActive]: !!movieData?.isActive,
      [MovieFieldsEnum.IsEmbed]: !!movieData?.isEmbed,
      [MovieFieldsEnum.PreviewMovie]: transformMovieFile(movieData?.previewMovie),
      [MovieFieldsEnum.FullMovie]: transformMovieFile(movieData?.fullMovie),
      [MovieFieldsEnum.ActionConfig]: removeTypenames(movieData?.actionConfig),
    };

    methods.reset(defaultValues);
  }, [movieData]);

  useEffect(() => {
    if (selectedAction === 'emailInput') {
      methods.setValue(MovieFieldsEnum.ActionConfig, {
        ...methods.getValues(MovieFieldsEnum.ActionConfig),
        redirect: null,
      });
    } else if (selectedAction === 'redirect') {
      methods.setValue(MovieFieldsEnum.ActionConfig, {
        ...methods.getValues(MovieFieldsEnum.ActionConfig),
        emailInput: null,
      });
    }
  }, [selectedAction, methods]);

  const [updateMovie] = useUpdateMovieMutation();
  const onSubmit = async (values: VideoDetailsValues) => {
    await updateMovie({
      variables: {
        input: {
          customerId: movieData?.customerId ?? '',
          id: movieData?.id ?? '',
          ...values,
        },
      },
    });
    setIsEditable(false);
  };

  return (
    <Box>
      <Box alignItems="center" display="flex" flexDirection="row" justifyContent="space-between">
        <Typography fontSize={36} fontWeight={700} pb={6} pt={4}>
          {`${movieData?.companyName}: ${movieData?.name}`}
        </Typography>
        {!isEditable && (
          <Button
            color="secondary"
            onClick={() => setIsEditable(true)}
            style={{
              padding: isLargeScreen ? '0 90px' : '10px 20px',
              textTransform: 'none',
              maxWidth: isLargeScreen ? '100%' : '30%',
              height: '48px',
            }}
            variant="outlined"
          >
            Edit
          </Button>
        )}
      </Box>
      <Paper style={{ padding: paperPadding }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item lg={6} sm={12} xs={12}>
                <Typography fontSize="24px" fontWeight={700} pb={6}>
                  Basic information
                </Typography>
                <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue}>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography>Active video</Typography>
                    <Switch
                      {...methods.register(MovieFieldsEnum.IsActive)}
                      checked={methods.watch(MovieFieldsEnum.IsActive)}
                      color="secondary"
                      disabled={!isEditable}
                      onChange={(e) => methods.setValue(MovieFieldsEnum.IsActive, e.target.checked)}
                    />
                  </Box>
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography>Embed mode (c2 card)</Typography>
                    <Switch
                      {...methods.register(MovieFieldsEnum.IsEmbed)}
                      checked={methods.watch(MovieFieldsEnum.IsEmbed)}
                      color="secondary"
                      disabled={!isEditable}
                      onChange={(e) => methods.setValue(MovieFieldsEnum.IsEmbed, e.target.checked)}
                    />
                  </Box>
                  <TextInput
                    {...methods.register(MovieFieldsEnum.Name)}
                    disabled={!isEditable}
                    errors={methods.formState.errors}
                    label="Video name"
                  />
                  <Box display="flex" flexDirection="row" gap={3}>
                    <DateInput
                      control={methods.control}
                      disabled={!isEditable}
                      errors={methods.formState.errors}
                      label="Valid from"
                      name={MovieFieldsEnum.ValidFrom}
                    />
                    <DateInput
                      control={methods.control}
                      disabled={!isEditable}
                      errors={methods.formState.errors}
                      label="Valid until"
                      name={MovieFieldsEnum.ValidUntil}
                    />
                  </Box>
                  <TextInput
                    {...methods.register(MovieFieldsEnum.ActorName)}
                    disabled={!isEditable}
                    errors={methods.formState.errors}
                    label="Actor’s name and surname"
                  />
                  <TextInput
                    {...methods.register(MovieFieldsEnum.AgencyName)}
                    disabled={!isEditable}
                    errors={methods.formState.errors}
                    label="Agency name"
                  />
                  <TextInput
                    {...methods.register(MovieFieldsEnum.Url)}
                    disabled={!isEditable}
                    errors={methods.formState.errors}
                    label="Widget url"
                  />
                </Box>
              </Grid>
              <Grid item lg={6} sm={12} xs={12}>
                <Typography fontSize="24px" fontWeight={700} pb={6}>
                  Videos
                </Typography>
                <Box display="flex" flexDirection="column" gap={3}>
                  <DragAndDropInput
                    {...methods.register(MovieFieldsEnum.PreviewMovie)}
                    disabled={!isEditable}
                    errors={methods.formState.errors}
                    initialFile={methods.watch(MovieFieldsEnum.PreviewMovie)}
                    label="Preview video"
                    onFileUploadComplete={(value) =>
                      methods.setValue(MovieFieldsEnum.PreviewMovie, value)
                    }
                  />
                  <DragAndDropInput
                    {...methods.register(MovieFieldsEnum.FullMovie)}
                    disabled={!isEditable}
                    errors={methods.formState.errors}
                    initialFile={methods.watch(MovieFieldsEnum.FullMovie)}
                    label="Full video"
                    onFileUploadComplete={(value) =>
                      methods.setValue(MovieFieldsEnum.FullMovie, value)
                    }
                  />
                  <Box>
                    <FormControl fullWidth margin="normal">
                      <Typography pb={1}>Action Type</Typography>
                      <Select
                        disabled={!isEditable}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Type' }}
                        onChange={(e) => setSelectedAction(e.target.value as MovieConfigSelectType)}
                        renderValue={selectedAction !== null ? undefined : () => 'Select option'}
                        value={selectedAction}
                      >
                        <MenuItem value="redirect">Redirect</MenuItem>
                        <MenuItem value="emailInput">Email</MenuItem>
                        <MenuItem value="copyToClipboard">Copy to clipboard</MenuItem>
                      </Select>
                    </FormControl>
                    {selectedAction === 'redirect' && <RedirectConfigForm disabled={!isEditable} />}
                    {selectedAction === 'emailInput' && (
                      <EmailInputConfigForm disabled={!isEditable} />
                    )}
                    {selectedAction === 'copyToClipboard' && (
                      <CopyToClipboardConfigForm disabled={!isEditable} />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {isEditable && (
              <Box display="flex" flexDirection="row" justifyContent="end" pt={6} width="100%">
                <Button
                  color="secondary"
                  disabled={!isEditable}
                  style={{ textTransform: 'none', padding: '13px 76px', fontWeight: '700' }}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            )}
            {movieData?.isEmbed && (
              <Box mt={2}>
                <Box display="flex" flexDirection="row" alignItems="center" mt={2}>
                  <PreviewButton
                    handleOnClick={(url) => window.open(url, '_blank')}
                    title="Preview"
                    url={c2cardUrl(movieData)}
                  />
                  <PreviewButton
                    handleOnClick={(url) => navigator.clipboard.writeText(url)}
                    marginLeft
                    title="Copy to clipboard"
                    url={c2cardUrl(movieData)}
                  />
                  <PreviewButton
                    handleOnClick={(url) => setShowQR(true)}
                    marginLeft
                    title="Generate QR code"
                    url={c2cardUrl(movieData)}
                  />
                </Box>
                {showQR && (
                  <Box display="flex" flexDirection="row" alignItems="center" mt={4}>
                    <div style={{ height: 'auto', margin: '0 auto', maxWidth: 200, width: '100%' }}>
                      <QRCode
                        size={256}
                        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                        value={c2cardUrl(movieData)}
                        viewBox="0 0 256 256"
                      />
                    </div>
                  </Box>
                )}
              </Box>
            )}
          </form>
        </FormProvider>
      </Paper>
    </Box>
  );
};

export default VideoDetails;

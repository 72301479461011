/* eslint-disable react/destructuring-assignment */
export interface SVGProps extends React.SVGProps<SVGSVGElement> {
  fill?: string;
}

const SVGIcon = ({ ...props }: SVGProps) => {
  return <svg {...props} />;
};

export type IconType = typeof SVGIcon;

export const Closer2Icon: IconType = (props) => (
  <SVGIcon {...props} fill="none" viewBox="0 0 153 24">
    <rect fill="#441078" height="100%" width="100%" />
    <path
      d="M130.152 0C129.138 0 128.053 0.65054 128.053 2.06172C128.053 3.4829 129.138 4.12343 130.152 4.12343C131.167 4.12343 132.252 3.47289 132.252 2.06172C132.252 0.640531 131.157 0 130.152 0Z"
      fill="white"
    />
    <path d="M131.871 7.17578H128.434V23.4493H131.871V7.17578Z" fill="white" />
    <path
      d="M144.47 6.89551C139.587 6.89551 136.04 10.4585 136.04 15.3626C136.04 20.3467 139.517 23.8296 144.5 23.8296C149.504 23.8296 153 20.3467 153 15.3626C153.01 10.4585 149.413 6.89551 144.47 6.89551ZM144.51 20.7471C141.054 20.7471 139.476 17.9547 139.476 15.3626C139.476 12.6703 141.204 9.93804 144.51 9.93804C147.655 9.93804 149.544 12.7003 149.544 15.3626C149.534 17.9547 147.966 20.7471 144.51 20.7471Z"
      fill="white"
    />
    <path
      d="M8.6909 20.5574C6.13889 20.5574 3.55674 18.936 3.55674 15.303C3.55674 12.2204 5.74705 9.98854 8.76123 9.98854C9.94681 9.98854 11.1726 10.4289 12.1472 11.2096L14.3777 8.87761C12.7199 7.33633 11.0118 6.67578 8.70095 6.67578C3.41608 6.67578 0 10.0686 0 15.303C0 20.5473 3.41608 23.9402 8.70095 23.9402C11.1424 23.9402 13.0213 23.2096 14.7896 21.5782L12.4184 19.2763C11.4439 20.117 10.1678 20.5574 8.6909 20.5574Z"
      fill="white"
    />
    <path d="M21.9533 1.86133H18.4167V23.4193H21.9533V1.86133Z" fill="white" />
    <path
      d="M34.1306 6.81543C29.2275 6.81543 25.6708 10.3884 25.6708 15.3125C25.6708 20.3167 29.1672 23.8096 34.1607 23.8096C39.1844 23.8096 42.6909 20.3167 42.6909 15.3125C42.6909 10.3884 39.0839 6.81543 34.1306 6.81543ZM34.1607 20.5969C30.7748 20.5969 29.2376 17.8546 29.2376 15.3125C29.2376 12.6703 30.9255 9.99808 34.1607 9.99808C37.2452 9.99808 39.0839 12.7003 39.0839 15.3125C39.0839 17.8546 37.5467 20.5969 34.1607 20.5969Z"
      fill="white"
    />
    <path
      d="M52.8286 13.6716C50.4976 13.5215 49.3623 12.8209 49.3623 11.5098C49.3623 10.2388 50.6082 9.51815 52.7784 9.51815C54.5366 9.51815 55.8227 9.9385 56.948 10.8893L58.8771 8.6274C57.1992 7.24625 55.3907 6.67578 52.7181 6.67578C50.1761 6.67578 45.8759 7.71665 45.8759 11.6399C45.8759 15.4931 50.0958 16.3839 52.6076 16.574C54.5065 16.6841 56.2748 17.0144 56.2748 18.6658C56.2748 19.1762 56.0638 19.6566 55.6519 20.0569C55.0189 20.6775 53.984 21.0277 52.8889 21.0077C51.1608 20.9777 48.5183 20.137 47.0916 18.8159L45.3936 21.2679C47.815 23.6699 50.3972 24.0002 52.8286 24.0002C57.9829 24.0002 59.8115 21.1779 59.8115 18.7659C59.7914 14.6424 56.0638 13.8718 52.8286 13.6716Z"
      fill="white"
    />
    <path
      d="M71.4261 20.6975C68.432 20.6975 66.3723 19.2162 66.0508 16.8242L66.0006 16.4639H79.3233C79.5745 13.2713 78.8913 10.7091 77.354 9.01773C75.9273 7.45643 73.7471 6.63574 71.0443 6.63574C65.8398 6.63574 62.3434 10.0586 62.3434 15.1629C62.3434 20.5273 65.7996 23.8601 71.3558 23.8601C73.9982 23.8601 76.6005 22.9293 78.198 21.4281L75.9575 19.2262C74.802 20.2371 72.8227 20.6975 71.4261 20.6975ZM66.1312 13.2312C66.7541 11.0294 68.643 9.7083 71.185 9.7083C73.8978 9.7083 75.6158 11.0094 75.9072 13.2713L75.9474 13.6216H66.0207L66.1312 13.2312Z"
      fill="white"
    />
    <path
      d="M91.2795 10.2288C92.6459 10.2288 93.4598 10.5891 94.2033 11.1595L95.8109 8.07696C94.8664 7.24626 93.3191 6.71582 91.8321 6.71582C90.2145 6.71582 88.5567 6.98605 87.3209 8.9677L86.8688 9.6883L86.4669 7.16619H83.1613V23.4398H86.7281V14.8426C86.7382 11.9502 88.4361 10.2288 91.2795 10.2288Z"
      fill="white"
    />
    <path
      d="M102 7.98685C102.251 5.80503 104.803 4.62404 107.144 4.62404C110.771 4.62404 112.931 6.17534 112.931 8.77751C112.931 10.3288 111.977 12.1803 107.456 12.1803C103.095 12.1803 98.0012 14.1019 98.0012 19.5365V23.5198H116.88V19.9368H101.91V19.5765C101.91 16.8642 105.155 15.6432 107.456 15.6432C111.806 15.6432 116.81 14.8526 116.81 8.68743C116.81 3.28293 111.786 1.36133 107.074 1.36133C102.743 1.36133 98.3327 3.41304 98.1117 7.99686H102V7.98685Z"
      fill="#00DF81"
    />
    <path
      d="M124.194 19.9865C123.803 19.6462 123.27 19.4561 122.697 19.4561C121.652 19.4561 120.537 20.1266 120.537 21.5878C120.537 23.0491 121.663 23.7196 122.697 23.7196C123.732 23.7196 124.858 23.0491 124.858 21.5878C124.868 20.9173 124.637 20.3668 124.194 19.9865Z"
      fill="white"
    />
  </SVGIcon>
);

export const DashboardIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="20" viewBox="0 0 20 20" width="20">
    <path
      d="M19.3333 7.58333L13 1.25C11.3333 -0.416667 8.75 -0.416667 7.08333 1.25L0.749995 7.58333C0.249995 8.08333 0 8.66666 0 9.33333V17.5C0 18.9167 1.08333 20 2.5 20H17.5C18.9167 20 20 18.9167 20 17.5V9.33333C20 8.66666 19.75 8 19.3333 7.58333ZM12.5 18.3333H7.5V15.0833C7.5 13.6667 8.58333 12.5833 10 12.5833C11.4167 12.5833 12.5 13.6667 12.5 15.0833V18.3333ZM18.3333 17.5C18.3333 18 18 18.3333 17.5 18.3333H14.1667V15.0833C14.1667 12.75 12.3333 10.9167 10 10.9167C7.66667 10.9167 5.83333 12.75 5.83333 15.0833V18.3333H2.5C2 18.3333 1.66667 18 1.66667 17.5V9.33333C1.66667 9.08333 1.75002 8.91667 1.91668 8.75L8.24999 2.41666C9.24999 1.41666 10.8333 1.41666 11.75 2.41666L18.0833 8.75C18.25 8.91667 18.3333 9.08333 18.3333 9.33333V17.5Z"
      fill={props.fill ?? 'white'}
    />
  </SVGIcon>
);

export const CustomerIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="22" viewBox="0 0 17 22" width="17">
    <path
      d="M8.49911 11C11.6155 11 14.1652 8.69375 14.1652 5.875C14.1652 3.05625 11.6155 0.75 8.49911 0.75C5.38275 0.75 2.83301 3.05625 2.83301 5.875C2.83301 8.69375 5.38275 11 8.49911 11ZM8.49911 2.37291C10.5767 2.37291 12.2765 3.91041 12.2765 5.78958C12.2765 7.66875 10.5767 9.20625 8.49911 9.20625C6.42154 9.20625 4.72171 7.66875 4.72171 5.78958C4.72171 3.91041 6.42154 2.37291 8.49911 2.37291Z"
      fill={props.fill ?? 'white'}
    />
    <path
      d="M8.49915 12.708C3.7774 12.708 0 16.1247 0 20.3955C0 20.908 0.37774 21.2497 0.94435 21.2497C1.51096 21.2497 1.8887 20.908 1.8887 20.3955C1.8887 17.0643 4.81619 14.4163 8.49915 14.4163C12.1821 14.4163 15.1096 17.0643 15.1096 20.3955C15.1096 20.908 15.4873 21.2497 16.054 21.2497C16.6206 21.2497 16.9983 20.908 16.9983 20.3955C17.0927 16.1247 13.2209 12.708 8.49915 12.708Z"
      fill={props.fill ?? 'white'}
    />
  </SVGIcon>
);

export const VideoIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="20" viewBox="0 0 20 20" width="20">
    <path
      d="M15.8333 20H4.16666C1.83333 20 0 18.1667 0 15.8333V4.16667C0 1.83333 1.83333 0 4.16666 0H15.8333C18.1667 0 20 1.83333 20 4.16667V15.8333C20 18.0833 18.0833 20 15.8333 20ZM4.16666 1.66667C2.75 1.66667 1.66667 2.75 1.66667 4.16667V15.8333C1.66667 17.25 2.75 18.3333 4.16666 18.3333H15.8333C17.25 18.3333 18.3333 17.25 18.3333 15.8333V4.16667C18.3333 2.75 17.25 1.66667 15.8333 1.66667H4.16666ZM7.75 14.1667C7.41666 14.1667 7.08332 14.0833 6.74999 13.9167C6.16666 13.5833 5.75 12.9167 5.75 12.25V7.83333C5.75 6.75 6.58333 5.91667 7.66666 5.91667C7.99999 5.91667 8.33333 6 8.66667 6.16667L13.0833 8.33333C14 8.83333 14.4166 10 13.8333 11C13.6666 11.3333 13.4167 11.5833 13.0833 11.75L8.58333 14C8.41666 14.0833 8.08333 14.1667 7.75 14.1667ZM7.75 7.5C7.66666 7.5 7.66665 7.5 7.58332 7.5C7.49999 7.58333 7.41667 7.66666 7.41667 7.75V12.1667C7.41667 12.3333 7.58333 12.4167 7.66666 12.4167C7.74999 12.4167 7.75 12.4167 7.83334 12.4167L12.3333 10.1667C12.4167 10.0833 12.4167 10 12.4167 9.91667C12.4167 9.83333 12.3333 9.75 12.25 9.66667L7.75 7.5C7.83333 7.5 7.83333 7.5 7.75 7.5Z"
      fill={props.fill ?? 'white'}
    />
  </SVGIcon>
);

export const LogoutIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="24" viewBox="0 0 24 24" width="24">
    <path
      d="M5 11.8002C5 12.3002 5.4 12.8002 6 12.8002H21.7L17.8 16.7002C17.6 16.9002 17.5 17.1001 17.5 17.4001C17.5 17.7001 17.6 17.9001 17.8 18.1001C18.2 18.5001 18.8 18.5001 19.2 18.1001L22.6 14.7002C23.4 13.9002 23.8 12.9002 23.8 11.8002C23.8 10.7002 23.4 9.70015 22.6 8.90015L19.3 5.60015C18.9 5.20015 18.3 5.20015 18 5.60015C17.6 6.00015 17.6 6.60016 18 7.00016L21.9 10.9001H6.2C5.5 10.8001 5 11.2002 5 11.8002Z"
      fill="#222222"
    />
    <path
      d="M8.70029 1C8.70029 0.5 8.30029 0 7.70029 0L2.90029 0C1.30029 0 0.000289917 1.29999 0.000289917 2.89999L0.000289917 20.8C0.000289917 22.4 1.30029 23.7 3.00029 23.7H7.80029C8.30029 23.7 8.80029 23.3 8.80029 22.7C8.80029 22.2 8.40029 21.7 7.80029 21.7H3.00029C2.40029 21.7 2.00029 21.2 2.00029 20.7L2.00029 2.8C2.00029 2.2 2.50029 1.8 3.00029 1.8L7.80029 1.8C8.20029 2 8.70029 1.5 8.70029 1Z"
      fill="#222222"
    />
  </SVGIcon>
);

export const Chevron: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="100%" viewBox="0 0 24 24" width="100%">
    <rect
      height="24"
      style={{ mixBlendMode: 'multiply' }}
      transform="matrix(-1 0 0 -1 24 24)"
      width="24"
    />
    <path
      d="M12 7.49999L19.5 15L18.45 16.05L12 9.59999L5.55 16.05L4.5 15L12 7.49999Z"
      fill="#653FFE"
    />
  </SVGIcon>
);

export const BackArrowIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="23" viewBox="0 0 24 23" width="24">
    <path
      d="M0 11.4901C0 11.2636 0.0445565 11.0457 0.124735 10.8453C0.204913 10.6101 0.329637 10.3835 0.516719 10.1919L9.76391 0.546555C10.4321 -0.150486 11.5546 -0.185361 12.2673 0.468115C12.98 1.12159 13.0156 2.21943 12.3475 2.91647L5.79065 9.75621H22.2272C23.2071 9.75621 24 10.5317 24 11.4901C24 12.4485 23.2071 13.224 22.2272 13.224H5.77284L12.3475 20.0811C13.0156 20.7782 12.98 21.876 12.2673 22.5295C11.9287 22.8431 11.4922 23 11.0557 23C10.5835 23 10.1114 22.817 9.76391 22.4511L0.516719 12.8058C0.294002 12.5792 0.160362 12.3004 0.0890923 12.0129C0.0445489 11.8473 0 11.6731 0 11.4901Z"
      fill="#653FFE"
    />
  </SVGIcon>
);

export const IntegrationsIcon: IconType = (props) => (
  <SVGIcon {...props} fill={props.fill ?? 'white'} height="20" viewBox="0 0 20 20" width="20">
    <g id="Group">
      <path
        d="M7.78379 17.1556C7.09189 17.8453 6.22703 18.1901 5.36216 18.1901C4.41081 18.1901 3.54594 17.8453 2.94054 17.1556C1.55675 15.7763 1.55675 13.6211 2.94054 12.2418L7.26486 7.93148C7.95675 7.24183 8.82163 6.89699 9.6865 6.89699C10.6379 6.89699 11.5027 7.24183 12.1081 7.93148C12.4541 8.27631 12.973 8.27631 13.3189 7.93148C13.4919 7.75907 13.5784 7.58664 13.5784 7.32802C13.5784 7.0694 13.4919 6.89699 13.3189 6.72458C12.2811 5.6901 10.9838 5.17285 9.60001 5.17285C8.21622 5.17285 6.91892 5.6901 5.88108 6.72458L1.55676 11.0349C0.605408 11.9832 0 13.2763 0 14.7418C0 16.1211 0.518922 17.4142 1.55676 18.4487C2.5946 19.4832 3.80541 20.0004 5.27568 20.0004C6.65947 20.0004 7.95675 19.4832 8.99459 18.4487C9.16756 18.2763 9.25407 18.1039 9.25407 17.8453C9.25407 17.5866 9.16756 17.4142 8.99459 17.2418C8.64865 16.8108 8.12973 16.8108 7.78379 17.1556Z"
        fill={props.fill ?? 'white'}
        id="Vector"
      />
      <path
        d="M14.1837 13.1897L18.5081 8.87931C20.4973 6.89655 20.4973 3.53448 18.5081 1.55173C17.5567 0.603451 16.2594 0 14.7892 0C13.4054 0 12.1081 0.517244 11.0702 1.55173C10.8972 1.72414 10.8108 1.89655 10.8108 2.15517C10.8108 2.41379 10.8972 2.58622 11.0702 2.75863C11.4162 3.10346 11.9351 3.10346 12.2811 2.75863C13.6648 1.37932 15.827 1.37932 17.2108 2.75863C18.5946 4.13794 18.5946 6.29311 17.2108 7.67242L12.8864 11.9828C12.281 12.5862 11.3297 12.931 10.4648 12.931C9.51348 12.931 8.6486 12.5862 8.04319 11.9828C7.87022 11.8103 7.61078 11.7241 7.4378 11.7241C7.26483 11.7241 7.00536 11.8103 6.83239 11.9828C6.65941 12.1552 6.57294 12.3276 6.57294 12.5862C6.57294 12.8448 6.65941 13.0172 6.83239 13.1897C7.78374 14.1379 9.08105 14.7414 10.5513 14.7414C11.9351 14.7414 13.2324 14.2241 14.1837 13.1897Z"
        fill={props.fill ?? 'white'}
        id="Vector_2"
      />
    </g>
  </SVGIcon>
);

export const CopyToClipboardIcon: IconType = (props) => (
  <SVGIcon {...props} fill="none" height="25" viewBox="0 0 22 25" width="22">
    <g id="Icon_copy">
      <path
        d="M1 21V9C1 7.34315 2.34315 6 4 6H13C14.6569 6 16 7.34315 16 9V21C16 22.6569 14.6569 24 13 24H4C2.34315 24 1 22.6569 1 21Z"
        id="Vector 1"
        stroke="#441078"
        strokeWidth="2"
      />
      <path
        d="M6 5V4C6 2.34315 7.34315 1 9 1H18C19.6569 1 21 2.34315 21 4V16C21 17.6569 19.6569 19 18 19H17"
        id="Vector 2"
        stroke="#441078"
        strokeWidth="2"
      />
      <path d="M4 12H13" id="Vector 3" stroke="#441078" strokeLinecap="round" strokeWidth="1.5" />
      <path d="M4 18H13" id="Vector 5" stroke="#441078" strokeLinecap="round" strokeWidth="1.5" />
      <path d="M4 15H13" id="Vector 4" stroke="#441078" strokeLinecap="round" strokeWidth="1.5" />
    </g>
  </SVGIcon>
);

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { createNewCustomerData } from './utlis';

const rows = [
  createNewCustomerData('MasterCard', 'mail@mail.pl', 'www.google.com'),
  createNewCustomerData('MasterCard2', 'mail@mail.pl', 'www.google.com'),
  createNewCustomerData('MasterCard3', 'mail@mail.pl', 'www.google.com'),
  createNewCustomerData('MasterCard4', 'mail@mail.pl', 'www.google.com'),
  createNewCustomerData('MasterCard5', 'mail@mail.pl', 'www.google.com'),
];

const NewCustomersTable = () => {
  return (
    <Paper sx={{ marginTop: 5, borderRadius: '8px', maxWidth: '700px' }} variant="outlined">
      <Box padding={2}>
        <Typography component="div" sx={{ fontSize: 24, fontWeight: 'bold' }}>
          New customers
        </Typography>
      </Box>
      <TableContainer>
        <Table aria-label="simple table" sx={{ minWidth: 650 }}>
          <TableHead sx={{ background: 'rgba(101, 63, 254, 0.10)' }}>
            <TableRow>
              <TableCell sx={{ fontSize: 16 }}>Name</TableCell>
              <TableCell sx={{ fontSize: 16 }}>Email</TableCell>
              <TableCell sx={{ fontSize: 16 }}>URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" sx={{ fontSize: 16 }}>
                  {row.name}
                </TableCell>
                <TableCell sx={{ fontSize: 16 }}>{row.email}</TableCell>
                <TableCell sx={{ fontSize: 16 }}>{row.url}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default NewCustomersTable;

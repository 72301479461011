import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import BackButton from '../BackButton';
import { TextInput } from '../Inputs';
import { ReferralCodeEnum } from '../../types';
import { useCreateReferralCodeMutation } from '../../hooks/api/createReferralCode/createReferralCode.generated';
import { ReferralCodesDocument } from '../../hooks/api/referrals/referrals.generated';
import { addNewReferralSchema } from './addNewReferralSchema';

interface AddNewReferralValues {
  [ReferralCodeEnum.Name]: string;
  [ReferralCodeEnum.Code]: string;
  [ReferralCodeEnum.Note]?: string | null;
}

const AddNewReferral = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AddNewReferralValues>({
    resolver: yupResolver(addNewReferralSchema()),
  });

  const [err, setErr] = useState();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const maxWidthValue = isLargeScreen ? '368px' : '488px';
  const paperPadding = isLargeScreen ? '32px' : '16px';

  const [createReferralCode] = useCreateReferralCodeMutation({
    refetchQueries: [{ query: ReferralCodesDocument }],
  });
  const onSubmit = async (values: AddNewReferralValues) => {
    try {
      const { data } = await createReferralCode({
        variables: {
          name: values[ReferralCodeEnum.Name],
          code: values[ReferralCodeEnum.Code],
          note: values[ReferralCodeEnum.Note],
        },
      });

      if (data && data.createReferralCode) {
        await navigate('/referrals');
      }
    } catch (error: any) {
      console.error('Error creating referral code:', error.message || error);
      setErr(error.message);
    }
  };
  return (
    <Box>
      <BackButton label="Referrals" path="/referrals" />
      <Typography fontSize={36} fontWeight={700} pt={4}>
        Create new referral code
      </Typography>
      <Paper style={{ padding: paperPadding }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item lg={6} sm={12} xs={12}>
              <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue}>
                <TextInput {...register(ReferralCodeEnum.Name)} errors={errors} label="Name" />
                <TextInput {...register(ReferralCodeEnum.Code)} errors={errors} label="Code" />
                <TextInput {...register(ReferralCodeEnum.Note)} errors={errors} label="Note" />
                {err && <FormHelperText error>{err}</FormHelperText>}
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" flexDirection="row" justifyContent="end" pt={6} width="100%">
            <Button
              color="secondary"
              style={{ textTransform: 'none', padding: '13px 76px', fontWeight: '700' }}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default AddNewReferral;

import { Box, Paper, Typography } from '@mui/material';
import { CopyToClipboardInput } from '../../components';
import { appConfig } from '../../app-config';

const Integrations = () => {
  const integrationLink = `<script async src="${appConfig.widgetIntegrationUrl}"></script>`;
  return (
    <Box>
      <Typography fontSize={36} fontWeight={700} pb={6} pt={12}>
        Integrations
      </Typography>
      <Paper style={{ padding: '36px', height: '65vh' }}>
        <Typography fontSize={24} fontWeight={700} pb={4}>
          URL to Customer
        </Typography>
        <CopyToClipboardInput initialValue={integrationLink} />
      </Paper>
    </Box>
  );
};

export default Integrations;

import { Toolbar, Typography } from '@mui/material';

const DashboardToolbar = () => {
  return (
    <Toolbar
      sx={{
        pl: { xs: 0, sm: 0 },
        pr: { xs: 1, sm: 1 },
        mb: { xs: 2, sm: 10 },
      }}
    >
      <Typography
        component="div"
        id="tableTitle"
        sx={{ flex: '1 1 100%', fontSize: '36px', fontWeight: 'bold' }}
        variant="h6"
      >
        Dashboard
      </Typography>
    </Toolbar>
  );
};
export default DashboardToolbar;

import { FC, useEffect } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneNumberInput, TextInput } from '../../Inputs';
import { CustomerType } from '../../../interfaces';
import { useUpdateCustomerMutation } from '../../../hooks/api/updateCustomer/updateCustomer.generated';
import { customerInformationFormSchema } from './customerInformationFormSchema';

interface CustomerInformationProps {
  isEditable: boolean;
  customerData?: Partial<CustomerType>;
  customerId?: string;
  closeEditMode: () => void;
}

export enum CustomerFieldsEnum {
  CompanyName = 'companyName',
  TaxId = 'taxId',
  Country = 'country',
  City = 'city',
  PostalCode = 'postalCode',
  Address = 'address',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
  Name = 'name',
}

interface CustomerInformationValues {
  [CustomerFieldsEnum.CompanyName]: string;
  [CustomerFieldsEnum.TaxId]: string;
  [CustomerFieldsEnum.Country]: string;
  [CustomerFieldsEnum.City]: string;
  [CustomerFieldsEnum.PostalCode]: string;
  [CustomerFieldsEnum.Address]: string;
  [CustomerFieldsEnum.Email]: string;
  [CustomerFieldsEnum.PhoneNumber]: string;
  [CustomerFieldsEnum.Name]: string;
}
const CustomerInformation: FC<CustomerInformationProps> = ({
  isEditable,
  customerData,
  customerId,
  closeEditMode,
}) => {
  const {
    register,
    reset,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CustomerInformationValues>({
    resolver: yupResolver(customerInformationFormSchema()),
  });

  const phoneNumberValue = watch(CustomerFieldsEnum.PhoneNumber);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const maxWidthValue = isLargeScreen ? '368px' : '488px';

  const [updateCustomer] = useUpdateCustomerMutation();

  useEffect(() => {
    const defaultValues = {
      [CustomerFieldsEnum.CompanyName]: customerData?.companyName,
      [CustomerFieldsEnum.TaxId]: customerData?.taxId,
      [CustomerFieldsEnum.Country]: customerData?.country,
      [CustomerFieldsEnum.City]: customerData?.city,
      [CustomerFieldsEnum.PostalCode]: customerData?.postalCode,
      [CustomerFieldsEnum.Address]: customerData?.address,
      [CustomerFieldsEnum.Email]: customerData?.email,
      [CustomerFieldsEnum.PhoneNumber]: customerData?.phoneNumber,
      [CustomerFieldsEnum.Name]: customerData?.name,
    };

    if (customerData?.phoneNumber) {
      defaultValues[CustomerFieldsEnum.PhoneNumber] = customerData.phoneNumber;
    }

    reset(defaultValues);
  }, [customerData]);

  const onSubmit = async (values: CustomerInformationValues) => {
    await updateCustomer({
      variables: {
        input: {
          id: customerId ?? '',
          ...values,
        },
      },
    });
    closeEditMode();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item lg={6} sm={12} xs={12}>
          <Typography fontSize="24px" fontWeight={700} pb={6}>
            Basic information
          </Typography>
          <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue}>
            <TextInput
              {...register(CustomerFieldsEnum.CompanyName)}
              disabled={!isEditable}
              errors={errors}
              label="Company name"
              onChange={(value) => setValue(CustomerFieldsEnum.CompanyName, value.target.value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.TaxId)}
              disabled={!isEditable}
              errors={errors}
              label="Tax ID"
              onChange={(value) => setValue(CustomerFieldsEnum.TaxId, value.target.value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.Country)}
              disabled={!isEditable}
              errors={errors}
              label="Country"
              onChange={(value) => setValue(CustomerFieldsEnum.Country, value.target.value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.City)}
              disabled={!isEditable}
              errors={errors}
              label="City"
              onChange={(value) => setValue(CustomerFieldsEnum.City, value.target.value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.PostalCode)}
              disabled={!isEditable}
              errors={errors}
              label="Postal Code"
              onChange={(value) => setValue(CustomerFieldsEnum.PostalCode, value.target.value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.Address)}
              disabled={!isEditable}
              errors={errors}
              label="Address"
              onChange={(value) => setValue(CustomerFieldsEnum.Address, value.target.value)}
            />
          </Box>
        </Grid>
        <Grid item lg={6} sm={12} xs={12}>
          <Typography fontSize="24px" fontWeight={700} pb={6}>
            Contact details
          </Typography>
          <Box display="flex" flexDirection="column" gap={4} maxWidth={maxWidthValue}>
            <TextInput
              {...register(CustomerFieldsEnum.Email)}
              disabled={!isEditable}
              errors={errors}
              label="E-mail"
              onChange={(value) => setValue(CustomerFieldsEnum.Email, value.target.value)}
            />
            <PhoneNumberInput
              disabled={!isEditable}
              errors={errors}
              initialValue={phoneNumberValue}
              label="Phone number"
              {...register(CustomerFieldsEnum.PhoneNumber)}
              onChange={(value) => setValue(CustomerFieldsEnum.PhoneNumber, value)}
            />
            <TextInput
              {...register(CustomerFieldsEnum.Name)}
              disabled={!isEditable}
              errors={errors}
              label="Name"
              onChange={(value) => setValue(CustomerFieldsEnum.Name, value.target.value)}
            />
          </Box>
        </Grid>
      </Grid>
      {isEditable && (
        <Box display="flex" flexDirection="row" justifyContent="end" pt={6} width="100%">
          <Button
            color="secondary"
            style={{ textTransform: 'none', padding: '13px 76px', fontWeight: '700' }}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
        </Box>
      )}
    </form>
  );
};

export default CustomerInformation;

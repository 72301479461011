import React from 'react';
import { Controller } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Typography, Box, FormHelperText } from '@mui/material';

interface CustomDatePickerProps {
  label: string;
  name: string;
  control: any;
  format?: string;
  errors?: any;
  disabled?: boolean;
}

const DateInput: React.FC<CustomDatePickerProps> = ({
  label,
  name,
  control,
  format = 'dd/MM/yyyy',
  errors,
  disabled,
}) => {
  const fieldError = errors && errors[name ?? ''];

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box>
        <Typography pb={1}>{label}</Typography>
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <DatePicker
              disabled={disabled}
              format={format}
              onChange={(date) => field.onChange(date?.toISOString())}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: fieldError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: fieldError ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                  },
                },
              }}
              value={field.value ? new Date(field.value) : null}
            />
          )}
        />
        {fieldError && <FormHelperText error>{fieldError.message}</FormHelperText>}
      </Box>
    </LocalizationProvider>
  );
};

export default DateInput;

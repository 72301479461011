import { Toolbar, Typography } from '@mui/material';

const CustomersToolbar = () => {
  return (
    <Toolbar>
      <Typography
        component="div"
        id="tableTitle"
        sx={{ flex: '1 1 100%', fontSize: '36px', fontWeight: 'bold', paddingBottom: '65px' }}
        variant="h6"
      >
        Customers
      </Typography>
    </Toolbar>
  );
};
export default CustomersToolbar;

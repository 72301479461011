import * as yup from 'yup';
import { MovieConfigSelectType, MovieFieldsEnum } from '../../types';
import { copyToClipboardSchema, emailInputSchema, redirectSchema } from './utils';

export const videoDetailsSchema = (formType: MovieConfigSelectType) =>
  yup.object().shape({
    [MovieFieldsEnum.IsActive]: yup.boolean().required('Active video field is required'),
    [MovieFieldsEnum.IsEmbed]: yup.boolean().default(false),
    [MovieFieldsEnum.Name]: yup
      .string()
      .max(100, 'Video name cannot be longer than 100 characters')
      .required('Video name field is required'),
    [MovieFieldsEnum.ActorName]: yup
      .string()
      .max(100, 'Actor’s name and surname cannot be longer than 100 characters')
      .required('Actor’s name and surname field is required'),
    [MovieFieldsEnum.AgencyName]: yup
      .string()
      .max(100, 'Agency name cannot be longer than 100 characters')
      .required('Agency name field is required'),
    [MovieFieldsEnum.Url]: yup
      .string()
      .max(100, 'Url cannot be longer than 200 characters')
      .required('Url field is required'),
    [MovieFieldsEnum.ValidFrom]: yup.string().required('Valid from field is required'),
    [MovieFieldsEnum.ValidUntil]: yup.string().required('Valid until field is required'),
    [MovieFieldsEnum.PreviewMovie]: yup
      .object()
      .shape({
        fileId: yup.string().required('Preview movie fileId field is required'),
        name: yup.string().required('Preview movie name field is required'),
      })
      .required('Preview movie field is required'),
    [MovieFieldsEnum.FullMovie]: yup
      .object()
      .shape({
        fileId: yup.string().required('Full movie fileId field is required'),
        name: yup.string().required('Full movie name field is required'),
      })
      .required('Full movie field is required'),
    [MovieFieldsEnum.ActionConfig]: yup.lazy(() => {
      if (formType === 'redirect') {
        return redirectSchema;
      }
      if (formType === 'copyToClipboard') {
        return copyToClipboardSchema;
      }
      if (formType === 'emailInput') {
        return emailInputSchema;
      }
      return yup
        .object()
        .test('formType-required', 'Action Type must be selected', () => formType !== null);
    }),
  });

import { useCallback, useMemo } from 'react';
import { Box, Button, TablePagination, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReferralCodeType } from '../../interfaces';
import { useExcel, usePagination } from '../../hooks';
import { createReferralCodeData, getComparator, stableSort } from '../utils';
import ReferralsToolbar from './ReferralsToolbar';
import ReferralsTable from './ReferralsTable';
import { useReferralCodesQuery } from '../../hooks/api/referrals/referrals.generated';
import { useReferralCodeUsagesQuery } from '../../hooks/api/referralUsage/referralUsage.generated';

const ReferralsView = () => {
  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    handleChangeRowsPerPage,
    handleChangePage,
    handleRequestSort,
  } = usePagination();

  const navigate = useNavigate();

  const { exportToXlsx } = useExcel();

  const { data: referralData, refetch: refetchReferralData } = useReferralCodesQuery({
    fetchPolicy: 'no-cache',
  });

  const { data: referralCodeUsageData } = useReferralCodeUsagesQuery({
    variables: {
      codeId: null,
    },
  });

  const rows =
    referralData?.referralCodes.map((referral) =>
      createReferralCodeData(
        referral.id,
        referral.name,
        referral.code,
        referral.note,
        referral.createdAt,
        referral.isActive,
      ),
    ) || [];

  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));

  const flexDirectionValue = isLargeScreen ? 'row' : 'column';
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows as any[], getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, rows],
  );

  const handleExportClick = useCallback(() => {
    if (!referralData || !referralCodeUsageData) return;
    exportToXlsx({
      fileName: `referral-report-${new Date().toISOString()}.xlsx`,
      sheets: [
        {
          name: 'codes',
          headers: ['ID', 'Code', 'Note', 'Name', 'Status', 'Created at'],
          data: referralData.referralCodes.map((row) => [
            row.id,
            row.code,
            row.note,
            row.name,
            row.isActive,
            new Date(row.createdAt),
          ]),
        },
        {
          name: 'usages',
          headers: ['ID', 'Code Name', 'Code Note', 'Name', 'Email', 'Role', 'Created at'],
          data: referralCodeUsageData.referralCodeUsages.map((row) => [
            row.id,
            (() => {
              const code = referralData.referralCodes.find((c) => c.id === row.codeId);
              return code ? code.name : '';
            })(),
            (() => {
              const code = referralData.referralCodes.find((c) => c.id === row.codeId);
              return code ? code.note : '';
            })(),
            row.name,
            row.email,
            row.role,
            new Date(row.createdAt),
          ]),
        },
      ],
    });
  }, [exportToXlsx, referralData, referralCodeUsageData]);

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Box
        display="flex"
        flexDirection={flexDirectionValue}
        justifyContent="space-between"
        pt="59px"
        width="100%"
      >
        <ReferralsToolbar />
        <Box display="flex">
          <Button
            color="secondary"
            onClick={handleExportClick}
            style={{
              padding: isLargeScreen ? '0 90px' : '10px 20px',
              textTransform: 'none',
              maxWidth: isLargeScreen ? '100%' : '30%',
              maxHeight: '48px',
              marginRight: '10px',
            }}
            variant="outlined"
          >
            Export to excel
          </Button>
          <Button
            color="secondary"
            onClick={() => navigate('/referrals/add-new-referral')}
            style={{
              padding: isLargeScreen ? '0 90px' : '10px 20px',
              textTransform: 'none',
              maxWidth: isLargeScreen ? '100%' : '30%',
              maxHeight: '48px',
            }}
            variant="outlined"
          >
            New code
          </Button>
        </Box>
      </Box>
      <ReferralsTable
        emptyRows={emptyRows}
        handleRequestSort={handleRequestSort}
        order={order}
        orderBy={orderBy as keyof Partial<ReferralCodeType>}
        refetch={refetchReferralData}
        visibleRows={visibleRows as any}
      />
      <TablePagination
        component="div"
        count={rows.length}
        labelRowsPerPage=""
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Box>
  );
};
export default ReferralsView;

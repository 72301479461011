import * as yup from 'yup';
import { EditReferralCodeNoteEnum } from '../../types';

export const editReferralCodeNoteSchema = () =>
  yup.object().shape({
    [EditReferralCodeNoteEnum.Note]: yup
      .string()
      .max(100, 'Note cannot be longer than 100 characters')
      .required('Note field is required'),
  });

import {
  Box,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Order, ReferralCodeType } from '../../interfaces';
import ReferralsTableHead from './ReferralsTableHead';
import { ReferralCodesDocument } from '../../hooks/api/referrals/referrals.generated';
import { useSetReferralCodeStatusMutation } from '../../hooks/api/setReferralCodeStatus/setReferralCodeStatus.generated';

interface ReferralsTableProps {
  handleRequestSort: (
    event: MouseEvent<unknown>,
    property: keyof Partial<ReferralCodeType>,
  ) => void;
  order: Order;
  orderBy: keyof Partial<ReferralCodeType>;
  visibleRows: Partial<ReferralCodeType>[];
  emptyRows: number;
  refetch: () => void;
}

const ReferralsTable = ({
  handleRequestSort,
  order,
  orderBy,
  visibleRows,
  emptyRows,
  refetch,
}: ReferralsTableProps) => {
  const navigate = useNavigate();
  const [SetReferralCodeStatus] = useSetReferralCodeStatusMutation({
    refetchQueries: [{ query: ReferralCodesDocument }],
  });
  const handleStatusChange = async (id: string, isActive: boolean) => {
    try {
      await SetReferralCodeStatus({
        variables: { id, isActive },
      });
      refetch();
    } catch (error: any) {
      console.error('Error editing referral code:', error.message || error);
    }
  };

  const handleToggle = (id: string | undefined, isActive: boolean | undefined) => {
    if (id) {
      handleStatusChange(id, !isActive);
    }
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const handleOnRowClick = (id: string | undefined) => navigate(`${id}`);
  return (
    <TableContainer sx={{ borderRadius: '8px 8px 0px 0px' }}>
      <Table aria-labelledby="tableTitle" sx={{ minWidth: 750 }}>
        <ReferralsTableHead onRequestSort={handleRequestSort} order={order} orderBy={orderBy} />
        <TableBody>
          {visibleRows.map((row, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                key={row.id}
                hover
                role="checkbox"
                sx={{ cursor: 'pointer', background: '#FFF' }}
                tabIndex={-1}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  onClick={() => handleOnRowClick(row.id)}
                  scope="row"
                  sx={{ fontSize: '16px' }}
                >
                  {row.name}
                </TableCell>
                <TableCell onClick={() => handleOnRowClick(row.id)} sx={{ fontSize: '16px' }}>
                  {row.code}
                </TableCell>
                <TableCell onClick={() => handleOnRowClick(row.id)} sx={{ fontSize: '16px' }}>
                  {row.note}
                </TableCell>
                <TableCell sx={{ fontSize: '16px', minWidth: '170px' }}>
                  <Box alignItems="center" display="flex">
                    <Typography sx={{ minWidth: '62px' }}>
                      {row.isActive ? 'Active' : 'Disabled'}
                    </Typography>
                    <Switch
                      checked={row.isActive}
                      {...label}
                      onChange={() => handleToggle(row.id, row.isActive)}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: 53 * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default ReferralsTable;

import { useNavigate, useParams } from 'react-router-dom';
import { Box, ButtonBase, Typography } from '@mui/material';
import React from 'react';
import { VideoDetails } from '../../components';
import { BackArrowIcon } from '../../assets/icons';
import { useMovieQuery } from '../../hooks/api/movie/movie.generated';

const CustomerVideoDetailsView = () => {
  const { videoId } = useParams();
  const navigate = useNavigate();

  const { data } = useMovieQuery({ variables: { id: videoId ?? '' } });

  const movieData = data?.movie;
  return (
    <Box>
      <ButtonBase
        onClick={() => navigate(-1)}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          cursor: 'pointer',
        }}
      >
        <BackArrowIcon />
        <Typography color="secondary" fontSize={24} mt="3px">
          {movieData?.companyName}
        </Typography>
      </ButtonBase>
      <VideoDetails movieData={movieData} />
    </Box>
  );
};

export default CustomerVideoDetailsView;

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type ReferralCodeQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;

export type ReferralCodeQuery = {
  __typename?: 'Query';
  referralCode: {
    __typename?: 'ReferralCodeType';
    id: string;
    name: string;
    note: string;
    code: string;
  };
};

export const ReferralCodeDocument = gql`
  query referralCode($id: String!) {
    referralCode(id: $id) {
      id
      name
      note
      code
    }
  }
`;

/**
 * __useReferralCodeQuery__
 *
 * To run a query within a React component, call `useReferralCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralCodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReferralCodeQuery(
  baseOptions: Apollo.QueryHookOptions<ReferralCodeQuery, ReferralCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ReferralCodeQuery, ReferralCodeQueryVariables>(
    ReferralCodeDocument,
    options,
  );
}
export function useReferralCodeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ReferralCodeQuery, ReferralCodeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ReferralCodeQuery, ReferralCodeQueryVariables>(
    ReferralCodeDocument,
    options,
  );
}
export type ReferralCodeQueryHookResult = ReturnType<typeof useReferralCodeQuery>;
export type ReferralCodeLazyQueryHookResult = ReturnType<typeof useReferralCodeLazyQuery>;
export type ReferralCodeQueryResult = Apollo.QueryResult<
  ReferralCodeQuery,
  ReferralCodeQueryVariables
>;
export function refetchReferralCodeQuery(variables: ReferralCodeQueryVariables) {
  return { query: ReferralCodeDocument, variables };
}

import { forwardRef } from 'react';
import { FormControl, FormHelperText, FormLabel, InputProps, OutlinedInput } from '@mui/material';
import { FieldError, FieldErrors } from 'react-hook-form';

export interface TextInputProps extends InputProps {
  label?: string;
  errors?: any;
  disabled?: boolean;
  value?: string | number | readonly string[] | undefined;
}

const getFieldError = (errors: FieldErrors, path: string) => {
  return path.split('.').reduce((acc: any, part: string) => acc && acc[part], errors) as
    | FieldError
    | undefined;
};

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ errors, label, disabled, value, ...inputProps }, ref) => {
    const fieldError = getFieldError(errors, inputProps.name ?? '');
    return (
      <FormControl>
        {!!label && <FormLabel style={{ paddingBottom: '8px', color: 'black' }}>{label}</FormLabel>}
        <OutlinedInput
          ref={ref}
          error={Boolean(fieldError)}
          {...inputProps}
          disabled={disabled}
          value={value}
        />
        {fieldError && (
          <FormHelperText error style={{ margin: '3px 0' }}>
            {fieldError.message}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

export default TextInput;

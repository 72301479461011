import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as Types from '../../../interfaces/api.types.generated.d';

const defaultOptions = {} as const;
export type CustomersQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CustomersQuery = {
  __typename?: 'Query';
  customers: {
    __typename?: 'CustomersQueryResultType';
    customers: Array<{
      __typename?: 'CustomerType';
      id: string;
      companyName: string;
      email: string;
      phoneNumber: string;
      moviesCount: number;
      createdAt: string;
      updatedAt: string;
      deletedAt?: string | null;
    }>;
  };
};

export const CustomersDocument = gql`
  query customers {
    customers {
      customers {
        id
        companyName
        email
        phoneNumber
        moviesCount
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
}
export function useCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
}
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export function refetchCustomersQuery(variables?: CustomersQueryVariables) {
  return { query: CustomersDocument, variables };
}

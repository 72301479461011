import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Closer2Icon,
  CustomerIcon,
  DashboardIcon,
  IntegrationsIcon,
  VideoIcon,
} from '../assets/icons';
import { theme } from '../styles/theme';

interface PanelSidebarProps {
  drawerWidth: number;
}

const PanelSidebar = ({ drawerWidth }: PanelSidebarProps) => {
  const path = useLocation();
  const navigate = useNavigate();
  return (
    <Drawer
      open
      sx={{
        display: { xs: 'none', sm: 'block' },
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: drawerWidth,
          backgroundColor: theme.palette.primary.main,
        },
      }}
      variant="permanent"
    >
      <Box>
        <Toolbar style={{ padding: '20px' }}>
          <Closer2Icon width={154} />
        </Toolbar>
        <List sx={{ paddingLeft: '10px' }}>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate('/')}
              sx={{
                backgroundColor:
                  path.pathname === '/' ? 'rgba(255, 255, 255, 0.10)' : theme.palette.primary.main,
                borderRadius: '4px',
                marginRight: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  height: '20px',
                  width: '20px',
                  minWidth: '20px',
                  marginRight: '10px',
                  marginBottom: '3px',
                }}
              >
                <DashboardIcon fill={path.pathname === '/' ? '#00DF81' : 'white'} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontWeight: path.pathname === '/' ? 'bold' : 'normal',
                  }}
                  variant="subtitle2"
                >
                  Dashboard
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate('/referrals')}
              sx={{
                backgroundColor:
                  path.pathname === '/referrals'
                    ? 'rgba(255, 255, 255, 0.10)'
                    : theme.palette.primary.main,
                borderRadius: '4px',
                marginRight: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  height: '20px',
                  width: '20px',
                  minWidth: '20px',
                  marginRight: '10px',
                  marginBottom: '2px',
                }}
              >
                <IntegrationsIcon
                  fill={path.pathname.startsWith('/referrals') ? '#00DF81' : 'white'}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontWeight: path.pathname.startsWith('/referrals') ? 'bold' : 'normal',
                  }}
                  variant="subtitle2"
                >
                  Referrals
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate('/customers')}
              sx={{
                backgroundColor:
                  path.pathname === '/customers'
                    ? 'rgba(255, 255, 255, 0.10)'
                    : theme.palette.primary.main,
                borderRadius: '4px',
                marginRight: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  height: '22px',
                  width: '17px',
                  minWidth: '17px',
                  marginRight: '10px',
                  marginBottom: '5px',
                }}
              >
                <CustomerIcon fill={path.pathname.startsWith('/customers') ? '#00DF81' : 'white'} />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  sx={{
                    fontWeight: path.pathname.startsWith('/customers') ? 'bold' : 'normal',
                  }}
                  variant="subtitle2"
                >
                  Customers
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate('/videos')}
              sx={{
                backgroundColor: path.pathname.startsWith('/videos')
                  ? 'rgba(255, 255, 255, 0.10)'
                  : theme.palette.primary.main,
                borderRadius: '4px',
                marginRight: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  height: '20px',
                  width: '20px',
                  minWidth: '20px',
                  marginRight: '10px',
                  marginBottom: '2px',
                }}
              >
                <VideoIcon fill={path.pathname.startsWith('/videos') ? '#00DF81' : 'white'} />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="subtitle2">Videos</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => navigate('/integrations')}
              sx={{
                backgroundColor: path.pathname.startsWith('/integrations')
                  ? 'rgba(255, 255, 255, 0.10)'
                  : theme.palette.primary.main,
                borderRadius: '4px',
                marginRight: 2,
              }}
            >
              <ListItemIcon
                sx={{
                  height: '20px',
                  width: '20px',
                  minWidth: '20px',
                  marginRight: '10px',
                  marginBottom: '2px',
                }}
              >
                <IntegrationsIcon
                  fill={path.pathname.startsWith('/integrations') ? '#00DF81' : 'white'}
                />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="subtitle2">Integrations</Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default PanelSidebar;

import * as yup from 'yup';
import {
  EmailInputFormFieldsEnum,
  RedirectFormFieldsEnum,
  CopyToClipboardFormFieldsEnum,
} from '../../types';

export function removeTypenames(value: any): any {
  if (value === null || value === undefined) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map(removeTypenames);
  }
  if (typeof value === 'object') {
    const newObj: any = {};
    for (const [key, subValue] of Object.entries(value)) {
      if (key !== '__typename') {
        newObj[key] = removeTypenames(subValue);
      }
    }
    return newObj;
  }
  return value;
}

export const redirectSchema = yup.object().shape({
  redirect: yup.object().shape({
    [RedirectFormFieldsEnum.ButtonLabel.replace('actionConfig.redirect.', '')]: yup
      .string()
      .max(100, 'Button label cannot be longer than 100 characters')
      .required('Button label field is required'),
    [RedirectFormFieldsEnum.TargetUrl.replace('actionConfig.redirect.', '')]: yup
      .string()
      .max(100, 'Target URL cannot be longer than 100 characters')
      .required('Target URL field is required'),
    [RedirectFormFieldsEnum.OpenInNewTab.replace('actionConfig.redirect.', '')]: yup
      .boolean()
      .default(false),
  }),
});

export const copyToClipboardSchema = yup.object().shape({
  copyToClipboard: yup.object().shape({
    [CopyToClipboardFormFieldsEnum.Label.replace('actionConfig.copyToClipboard.', '')]: yup
      .string()
      .max(100, 'Button label cannot be longer than 100 characters')
      .required('Button label field is required'),
    [CopyToClipboardFormFieldsEnum.LabelAfterClick.replace('actionConfig.copyToClipboard.', '')]:
      yup
        .string()
        .max(100, 'Button label cannot be longer than 100 characters')
        .required('Button label field is required'),
    [CopyToClipboardFormFieldsEnum.Value.replace('actionConfig.copyToClipboard.', '')]: yup
      .string()
      .max(200, 'Text to be copied to clipboard cannot be more than 200 characters')
      .required('Text to be copied to clipboard required'),
    [CopyToClipboardFormFieldsEnum.IconOn.replace('actionConfig.copyToClipboard.', '')]: yup
      .boolean()
      .default(false),
  }),
});

export const emailInputSchema = yup.object().shape({
  emailInput: yup.object().shape({
    [EmailInputFormFieldsEnum.ButtonLabelBeforeClick.replace('actionConfig.emailInput.', '')]: yup
      .string()
      .max(100, 'Button label before click cannot be longer than 100 characters')
      .required('Button label before click field is required'),
    [EmailInputFormFieldsEnum.ButtonLabelAfterClick.replace('actionConfig.emailInput.', '')]: yup
      .string()
      .max(100, 'Button label after click cannot be longer than 100 characters')
      .required('Button label after click field is required'),
    [EmailInputFormFieldsEnum.TargetUrl.replace('actionConfig.emailInput.', '')]: yup
      .string()
      .max(2000, 'Target URL after click cannot be longer than 2000 characters')
      .required('Target URL after click field is required'),
    [EmailInputFormFieldsEnum.Disclaimer.replace('actionConfig.emailInput.', '')]: yup
      .string()
      .nullable()
      .max(100, 'Disclaimer cannot be longer than 100 characters')
      .notRequired(),
  }),
});

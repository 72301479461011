import { Box, Grid } from '@mui/material';
import DashboardToolbar from './DashboardToolbar';
import DashboardCard from './DashboardCard';
import NewCustomersTable from './NewCustomersTable';

const DashboardView = () => {
  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <DashboardToolbar />
      <Grid columns={12} container direction="row" spacing={{ xs: 2, md: 3 }}>
        <Grid item md={4} sm={4} xs={4}>
          <DashboardCard data={111} label="All videos" />
        </Grid>
        <Grid item md={4} sm={4} xs={4}>
          <DashboardCard data={111} label="Number of customers" />
        </Grid>
        <Grid item md={4} sm={4} xs={4}>
          <DashboardCard data="Pizza hut" email="mail@mail.pl" label="Video with the most clicks" />
        </Grid>
      </Grid>
      <NewCustomersTable />
    </Box>
  );
};
export default DashboardView;
